import React, { useEffect, useState } from 'react';
import Constants from '../../../../core/Constants';
import swipeStyle from "./Login.module.css";
import { setToken, updateLoaderStatus } from '../../../../core/redux/actions';
import $ from 'jquery';
import { login, verifyOTP } from '../../../../services/api.services';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

function DefaultLogin() {
  const [cookies, setCookie] = useCookies(['user']);
  const [otp, setOTP] = useState(['', '', '', '', '', '']);
  const [otpId, setOTPId] = useState('');
  const dispatch = useDispatch();
  const [verify_error, setVerifyError] = useState('');
  const [sos_error, setSosError] = useState('');
  const [mobile, setmobile] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (mobile.length === 10) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
    }
  }, [mobile]);
  

  const movetoNext = (current, nextFieldID) => {
    if (current.target.value.length >= current.target.maxLength) {
      document.getElementById(nextFieldID).focus();
      alert()
    }
  }


  const callLogin = async () => {
    dispatch(updateLoaderStatus(true));
    let result = await login(mobile);
    if (result.data.status) {
      setOTPId(result.data.data.otp_id);
      $('.dvLoginDetailsContent1').animate({ 'left': '-100vw' }, 50).fadeOut(0);
      $('.dvLoginOTP').fadeIn(0).animate({ 'left': '0px' }, 500);
      $('.otp-form-control').val('');
    } else {
      setSosError(result.data.message);
    }
    dispatch(updateLoaderStatus(false));
  }

  const verifyLoginOTP = async () => {
    dispatch(updateLoaderStatus(true));
    let result = await verifyOTP(otpId.toString(), otp.join(''));
    if (result.data.status) {
      $('.dvLoginDetails').fadeOut();
      $('.dvMessage').fadeIn(0);
      $('.dvLoginOTP').css('left', '100vw');
      $('.btnClose').click();
      setCookie('Token', result.data.data.token, { path: '/profile' });
      localStorage.setItem('Token',result.data.data.token);
      dispatch(setToken(result.data.data.token));
      setTimeout(() => {
        navigate('/profile', { replace: true });  
      }, 100);
    } else {
      setVerifyError(result.data.message);
    }
    dispatch(updateLoaderStatus(false));
  }

  return (
    <>
      <section className="shareDetailsOverlay" id="loginOverlayTwo" style={{maxWidth:'414px',margin:'0 auto'}}>
        <div className="dvLoginDetails">
          <div className="dvLogoHolder">
            <div className="dvLogo1">
              <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/logo.png`} alt="" className="imgLogoNew" />
            </div>
            <div className="vr">&nbsp;</div>
            <div className="dvLogo2">
              <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/logo2.png`} alt="" className="imgLogo2" />
            </div>
          </div>
          <div className="dvLoginDetailsContent" style={{maxWidth:'390px',margin:'0 auto',overflow:'hidden'}}>
            <div className="btnHolder" style={{"marginBottom":'80px'}}>
              <button className="btnClose" id="closeSlider"
                onClick={() => {
                  $('#loginOverlayTwo, .dvMessage').fadeOut();
                  $(`#${swipeStyle['slider']}`).animate({ left: '-10px' }, 300);
                  $(`.${swipeStyle['slide-text']}`).fadeTo(300, 1);
                  $('body').css('overflow', 'auto');
                }}><span>
                  CLOSE </span>&#10005;</button>
            </div>
            <div className="dvLoginDetailsContent1" style={{"position":'relative'}}>
              {/* <a href="tel:6177787312"></a>  */}
              <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/user.png`} alt="" className="imgUser" />
              <h2 className="login-heading">&nbsp; Login</h2>
              <span className="orgBrd50"></span>
              <p style={{ "marginBottom": '35px',"marginTop":'15px',"fontSize":'medium'}}>Keep your <strong>Personal & Medical deatils</strong> always updated.</p>
              <div className="dvLoginForm">
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-prepend">
                      <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/mobile.png`} alt="" className="icon" />
                    </div>
                    <div className="vrOrg" style={{ "paddingLeft": '8px', 'paddingRight': '5px'}}> +91 </div>
                    <input type="text" className="form-control" id="mobileInput" minLength="10" maxLength="10" onKeyPress={(e) => { Constants.checkPhoneKey(e) }}
                      placeholder="Enter Your Mobile Number" onChange={(e) => setmobile(e.target.value)} />
                  </div>
                </div>
                <button className={`nameHeading ${showConfirm ? '' : 'hide'}`}
                  onClick={() => callLogin()} style={{"position":'absolute',"bottom":'-50%',cursor:'pointer'}}>Confirm</button>
                <span className={`error ${swipeStyle['error']}`}> 
                  {sos_error}
                </span>
              </div>
            </div>
            <div className="dvLoginOTP" style={{"position":'relative'}}>
              <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/otp.png`} alt="" className="imgUser" />
              <h2 className="login-heading" style={{"fontSize":'1.88rem',"color":'#FF9E00',"display":'block'}}>Enter Verification Code</h2>
              <p style={{"fontSize":'.85rem'}}>We have sent an OTP on your mobile number
                <br /> <strong style={{"fontSize":'1.12rem'}}>+91 {mobile}</strong>
              </p>
              <span className="orgBrd50"></span>
              {/* <Link to='/signup'><span>Signup</span></Link> */}
              <h4 className="txtBlue" 
               onClick={() => {
                 
                $('.dvLoginDetailsContent1').css('left', '0');
                $('#loginOverlayTwo, .dvLoginDetails, .dvLoginDetailsContent, .dvLoginDetailsContent1').fadeIn(300);
                $('.dvLoginOTP').fadeOut(0);
                $('body').css('overflow', 'hidden');
                $('#locker').text('lock_outline');
                navigate(`/`)
                // $('#mobileInput').val('');
              }}
              style={{"color":'#1068B2',"marginBottom":'30px',"marginTop":'15px',cursor:'pointer'}}>Change Phone Number?</h4>
              <div className="dvOtp">
             
                <input style={{"fontSize":'2.8rem'}} 
                type="number" size="2" 
                onKeyUp={(e) => movetoNext(e, 'second')} 
                id="first" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    otp[0] = e.target.value;
                    setOTP(otp)
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[0] = '';
                      setOTP(otp)
                      document.getElementById('first').focus();
                    }
                  }} />
                <input style={{"fontSize":'2.8rem'}} type="number" size="2" 
                onKeyUp={(e) => movetoNext(e, 'third')} id="second" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    otp[1] = e.target.value;
                    setOTP(otp)
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[1] = '';
                      setOTP(otp)
                      document.getElementById('first').focus();
                    }
                  }} />
                <input style={{"fontSize":'2.8rem'}} type="number" size="2" 
                onKeyUp={(e) => movetoNext(e, 'fourth')} id="third" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    otp[2] = e.target.value;
                    setOTP(otp)
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[2] = '';
                      setOTP(otp)
                      document.getElementById('second').focus();
                    }
                  }} />
                <input style={{"fontSize":'2.8rem'}} type="number" size="2"
                 onKeyUp={(e) => movetoNext(e, 'fifth')} id="fourth" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    otp[3] = e.target.value;
                    setOTP(otp)
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[3] = '';
                      setOTP(otp)
                      document.getElementById('third').focus();
                    }
                  }} />
                <input style={{"fontSize":'2.8rem'}} type="number" size="2"
                 onKeyUp={(e) => movetoNext(e, 'sixth')}
                 id="fifth" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    otp[4] = e.target.value;
                    setOTP(otp)
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[4] = '';
                      setOTP(otp)
                      document.getElementById('fourth').focus();
                    }
                  }} />
                <input style={{"fontSize":'2.8rem'}} type="number" size="2" 
                id="sixth" maxLength="1" 
              className="otp-form-control" onChange={(e) => {
                  otp[5] = e.target.value;
                  setOTP(otp);
                }} onKeyDown={(e) => {
                  if (e.keyCode === 8) {
                    otp[5] = '';
                    setOTP(otp);
                    document.getElementById('sixth').value = '';
                    document.getElementById('fifth').focus();
                    
                  }
                }} />
              </div>
              <button className="btnResendOtp">
                OTP not received?
                <span>Resend OTP</span>
              </button>
              <button className="nameHeading"
                onClick={() => verifyLoginOTP()} style={{cursor:'pointer'}} >Verify</button>
              <span className={`error ${swipeStyle['error']}`}>
                {verify_error}
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default DefaultLogin;