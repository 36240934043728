import React, { useEffect, useState } from 'react';
import scannedProfileStyle from './ScannedProfile.module.css';
import dateFormat from 'dateformat';
import swipeStyle from "./swipe/Swipe.module.css";
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import { scanProfile, sos, verifyOTP } from '../../../services/api.services';
import Constants from '../../../core/Constants';
import { useDispatch } from 'react-redux';
import { updateLoaderStatus } from '../../../core/redux/actions';
import style from './ScannedProfile.module.css';

function DefaultScannedProfile(props) {

  const { profile_web_link } = useParams();
  const [profile, setProfile] = useState({});
  const [location, setLocation] = useState({});
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [otp, setOTP] = useState(['', '', '', '', '', '']);
  const [otpId, setOTPId] = useState('');
  const [verify_error, setVerifyError] = useState('');
  const [sos_error, setSosError] = useState('');
  const dispatch = useDispatch();

  const movetoNext = (current, nextFieldID) => {
    if (current.target.value.length >= current.target.maxLength) {
      document.getElementById(nextFieldID).focus();
    }
  }

  useEffect(() => {
    if (props.location) {
      setLocation(props.location);
      setProfile(props.profile);
    }
  }, [props]);

  useEffect(() => {
    // (async () => {
    //   dispatch(updateLoaderStatus(true));
    //   let result = await scanProfile(profile_web_link);
    //   if (result.data.status) {
    //     setProfile(result.data.data);
    //   }
    //   dispatch(updateLoaderStatus(false));
    // })();
  }, []);

  const callSOS = async () => {
    dispatch(updateLoaderStatus(true));
    let result = await sos(profile.profile_code, phone, name, location);
    
    if (result.data.status) {
      setOTPId(result.data.data.otp_id);
      $('.dvShareDetailsContent1').animate({ 'left': '-100vw' }, 50).fadeOut(0);
      $('.dvShareOTP').fadeIn(0).animate({ 'left': '0px' }, 500);
    } else {
      setSosError(result.data.message);
    }
    dispatch(updateLoaderStatus(false));
  }

  const verifySOSOTP = async () => {
    dispatch(updateLoaderStatus(true));
    let result = await verifyOTP(otpId.toString(), otp.join(''));
    
    if (result.data.status) {
      $('.dvShareDetails').fadeOut();
      $('.dvMessage').fadeIn(0);
      $('.dvShareOTP').css('left', '100vw')
    } else {
      setVerifyError(result.data.message);
    }
    dispatch(updateLoaderStatus(false));
  }
  return (
    <>
      {/* <Header /> */}
      <section className="formDetails">
        <h4 className="formHeading" style={{ letterSpacing: '1px' }}>
          Find my details below
          <span className="dvPopover">
            <span className="dvI">
              i
            </span>
            <span className="popover" id="detailsPopover" role="tooltip" x-placement="top">
              <span className="arrow"></span>
              <span className="close"
                onClick={() => {
                  document.getElementById('detailsPopover').style.display = 'none';
                }}>x</span>
              <span className="popover-header"></span>
              <span className="popover-body">
                <h6>Keep your Personal & Medical details always updated.</h6>
                <p>In case of any emergency, people can get easy access to your details. Click on the menu
                  icon to login and update the details.</p>
              </span>
            </span>
          </span>
        </h4>
        <h3 className="nameHeading">
          <a id='sos_call' href={`${profile && profile.contacts && profile.contacts.length > 0 ?
            (profile.contacts[0].contact_mobile ? 'tel:'+profile.contacts[0].contact_mobile : (profile.contacts[1].contact_mobile ? 'tel:'+profile.contacts[1].contact_mobile : 'javascript:void(0)')) : 'javascript:void(0)'}`}
            onClick={()=>{
              if(profile && profile.contacts && profile.contacts.length > 0 && (profile.contacts[0].contact_mobile || profile.contacts[1].contact_mobile)){
                //
              }else{
                $('.sosNotFound').html('No Emergency Number Assigned');
                $('.sosNotFound').css('display','block')
                setTimeout(()=>{
                  $('.sosNotFound').css('display','none')
                },5000);
              }
              return false;
            }}
            ></a>
          {profile.first_name} {profile.middle_name} {profile.last_name}
        </h3>
        {profile.contract_company_name && profile.contract_company_name.toLowerCase() !== 'default' ?
          <div className="form-group">
            <label className="lbl">Company</label>
            <div className="input-group">
              <div className="input-prepend">
                <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/company.png`} alt="" className="icon" />
              </div>
              <label className="form-control">
                {profile.contract_company_name}
              </label>
            </div>
          </div> : null}

        {profile.company_name && profile.company_name.toLowerCase() !== 'default' && profile.employee_id ?
          <div className="form-group">
            <label className="lbl">Employee ID</label>
            <div className="input-group">
              <div className="input-prepend">
                <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/employeeid.png`} alt="" className="icon" />
              </div>
              <label className="form-control">
                {profile.employee_id}
              </label>
            </div>
          </div> : null}

        {profile.landmark ?
          <div className="form-group">
            <label className="lbl">Nearby Landmark</label>
            <div className="input-group">
              <div className="input-prepend">
                <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/landmark.png`} alt="" className="icon" />
              </div>
              <label className="form-control">
                {profile.landmark}
              </label>
            </div>
          </div> : null}
        {profile.city ?
          <div className="form-group">
            <label className="lbl">City</label>
            <div className="input-group">
              <div className="input-prepend">
                <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/city.png`} alt="" className="icon" />
              </div>
              <label className="form-control">
                {profile.city}
              </label>
            </div>
          </div> : null}
        {profile.primary_physician_name ?
          <div className="form-group">
            <label className="lbl">Primary Physician</label>
            <div className="input-group">
              <div className="input-prepend">
                <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/primaryphysician.png`} alt="" className="icon" />
              </div>
              <label className="form-control ">
                {profile.primary_physician_name}
              </label>
              {profile.primary_physician_mobile ?
                <a className="btnIcon" href={`tel:${profile.primary_physician_mobile}`}>
                  <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/call.png`} alt="" className="callIcon" />
                </a> : null}
            </div>
          </div> : null}
        {profile.medicalaim_policy_corporate_id || profile.medicalaim_policy_personal_id ?
          <div className="form-group">
            <label className="lbl">Mediclaim-ID</label>
            <div className="input-group">
              <div className="input-prepend">
                <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/mediclaimid.png`} alt="" className="icon" />
              </div>
              <label className="form-control">
                {profile.medicalaim_policy_corporate_id || profile.medicalaim_policy_personal_id}
              </label>
            </div>
          </div> : null}
        {profile.allergies ?
          <div className="form-group">
            <label className="lbl">Allergies</label>
            <div className="input-group">
              <div className="input-prepend">
                <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/allergies.png`} alt="" className="icon" />
              </div>
              <label className="form-control">
                {profile.allergies}
              </label>
            </div>
          </div> : null}
        {profile.medical_condition ?
          <div className="form-group">
            <label className="lbl">Medical Conditions</label>
            <div className="input-group">
              <div className="input-prepend">
                <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/medicalconditions.png`} alt="" className="icon" />
              </div>
              <label className="form-control">
                {profile.medical_condition}
              </label>
            </div>
          </div> : null}

        {profile.medicalaim_policy_corporate || profile.medicalaim_policy_personal ?
          <>
            <div className="form-group form-white form-shadow myMediclaimPolicy">
              <div className="input-group">
                <div className="input-prepend">
                  <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/mymediclaimpolicy.png`} alt="" className="icon" />
                </div>
                <label className="form-control">
                  My Mediclaim Policy
                </label>
              </div>
            </div>
            <div className="form-group form-white">
              {profile.medicalaim_policy_corporate ?
                <div className="input-group">
                  <div className="input-prepend">
                    <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/company.png`} alt="" className="icon" />
                  </div>
                  <div className="form-control-group">
                    <button className="btnIcon" onClick={() => {
                      var win = window.open(Constants.HOST + profile.medicalaim_policy_corporate, '_blank');
                      win.focus();
                    }}>
                      <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/righht-arrow.png`} alt="" className="arrowIcon" />
                    </button>
                    <label className="form-control">
                      Company
                    </label>
                    <label className="date">
                      Expiry Date: <strong>{profile.medicalaim_policy_corporate_expiry ? dateFormat(profile.medicalaim_policy_corporate_expiry, "yyyy-mm-dd") : 'Not Mentioned'}</strong>
                      {
                        !profile.medicalaim_policy_corporate_expiry ?
                          <span className="dvPopover">
                            <span className="dvI">
                              i
                            </span>
                            <span id="personalPopover" className="popover bs-popover-top" role="tooltip"
                              x-placement="top">
                              <span className="arrow"></span>
                              <span className="close"
                                onClick={() => {
                                  document.getElementById('personalPopover').style.display = 'none';
                                }}>x</span>
                              <span className="popover-header"></span>
                              <span className="popover-body">
                                <h6>Keep your Personal & Medical details always updated.</h6>
                                <p>In case of any emergency, people can get easy access to your details.
                                  Click on the menu icon to login and update the details.</p>
                              </span>
                            </span>
                          </span>
                          : null}
                    </label>
                  </div>
                </div>
                : null}
              {profile.medicalaim_policy_corporate && profile.medicalaim_policy_personal ?
                <label className="hr">-------------------------------------------------</label> : null}
              {profile.medicalaim_policy_personal ?
                <div className="input-group">
                  <div className="input-prepend">
                    <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/personal.png`} alt="" className="icon" />
                  </div>
                  <div className="form-control-group">
                    <button className="btnIcon" onClick={() => {
                      var win = window.open(Constants.HOST + profile.medicalaim_policy_personal, '_blank');
                      win.focus();
                    }}>
                      <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/righht-arrow.png`} alt="" className="arrowIcon" />
                    </button>
                    <label className="form-control">
                      Personal
                    </label>
                    <label className="date">
                      Expiry Date: <strong>
                        {
                          profile.medicalaim_policy_personal_expiry ? dateFormat(profile.medicalaim_policy_personal_expiry, "yyyy-mm-dd") : 'Not Mentioned'
                        }

                        {
                          !profile.medicalaim_policy_personal_expiry ?
                            <span className="dvPopover" id="dvPopover">
                              <span className="dvI">
                                i
                              </span>
                              <span id="personalPopover" className="popover bs-popover-top" role="tooltip"
                                x-placement="top">
                                <span className="arrow"></span>
                                <span className="close"
                                  onClick={() => {
                                    document.getElementById('personalPopover').style.display = 'none';
                                  }}>x</span>
                                <span className="popover-header"></span>
                                <span className="popover-body">
                                  <h6>Keep your Personal & Medical details always updated.</h6>
                                  <p>In case of any emergency, people can get easy access to your details.
                                    Click on the menu icon to login and update the details.</p>
                                </span>
                              </span>
                            </span>
                            : null}
                      </strong>
                    </label>
                  </div>
                </div>
                : null}
            </div>
          </>
          : null}
      </section>
      <section className="tollfree">
        {/* <h3 className="blueHeading">Policy Toll Free Number</h3>
        <h1 className="number">1800-425-9449</h1> */}
        <h3 className="blackHeading">Prepare and Respond!</h3>
        <div className="box-group">
          {profile.blood_group ?
            <div className="dvBox">
              <div className="heading">BLOOD GROUP</div>
              <div className="hr">--------------------</div>
              <div className="ans">{profile.blood_group}</div>
            </div>
            : null}
          <div className="dvBox">
            <div className="heading" >AMBULANCE</div>
            <div className="hr">--------------------</div>
            <div className="ans number">
              <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/call.png`} alt="" className="icon" />
              102
            </div>
          </div>
        </div>
      </section>
      {profile.banner ?
        <section className="clientBanner">
          <img src={Constants.HOST + profile.banner} alt="" className="client-banner" />
        </section>
        : null}
      <section className="resources">
        <h1 className="resources-heading">
          Helpful Resources
        </h1>
        <h4 className="resources-desc">
          Explore quick resources to respond to crises.
        </h4>
        {
          profile && profile?.helpful_resource ?
            profile.helpful_resource.map((hr) => {
              return <>
                <div className="card">
                  <a href={hr.link} className="btn">
                    {/* <img src={`${Constants.HOST}${hr.image}`} className="card-img-top" alt="" /> */}
                    <img src={`${Constants.HOST}${hr.image}`} className="card-img-top" alt="" />
                    <div className="card-body">
                      <h5 className="card-title">{hr.heading}</h5>
                      {/* <p className="card-text">Victims | Medanta</p> */}
                    </div>
                  </a>
                </div>
              </>;
            }) : "not found"
        }
      </section>
      {profile.company_emergency_contact_name || profile.policy_issuer_contact_name || profile.policy_relationship_manager_name ?
        <section className="emergency">
          <div className="formDetails">
            <h3 className="nameHeading">
              Emergency Contacts
            </h3>
            {profile.company_emergency_contact_name ?
              <div className="form-group">
                <label className="lbl">Company HR</label>
                <div className="input-group">
                  <div className="input-prepend">
                    <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/companyhr.png`} alt="" className="icon" />
                  </div>
                  <label className="form-control">
                    {profile.company_emergency_contact_name}
                  </label>
                  <a className="btnIcon" href={`tel:${profile.company_emergency_contact_number}`}>
                    <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/call.png`} alt="" className="callIcon" />
                  </a>
                </div>
              </div>
              : null}

            {profile.policy_issuer_contact_name ?
              <div className="form-group">
                <label className="lbl">Policy Issuer</label>
                <div className="input-group">
                  <div className="input-prepend">
                    <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/policyissuer.png`} alt="" className="icon" />
                  </div>
                  <label className="form-control">
                    {profile.policy_issuer_contact_name}
                  </label>
                  <a className="btnIcon" href={`tel:${profile.policy_issuer_contact_number}`}>
                    <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/call.png`} alt="" className="callIcon" />
                  </a>
                </div>
              </div>
              : null}
            {profile.policy_relationship_manager_name ?
              <div className="form-group">
                <label className="lbl">Policy Relationship Manager</label>
                <div className="input-group">
                  <div className="input-prepend">
                    <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/policyrelationshipmanager.png`} alt="" className="icon" />
                  </div>
                  <label className="form-control">
                    {profile.policy_relationship_manager_name}
                  </label>
                  <a className="btnIcon" href={`tel:${profile.policy_relationship_manager_number}`}>
                    <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/call.png`} alt="" className="callIcon" />
                  </a>
                </div>
              </div>
              : null}
          </div>
        </section>
        : null}
      {profile.care_n_secure_banner ?
        <section className="careNsecureBanner">
          <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/carensecure.png`} alt="" className="careNsecure" />
        </section>
        : null}
      <section className="footer">
        {/* <div className="asianLogo">
          {profile.logo ?
            <img src={Constants.HOST + profile.logo} alt="" className="asianLogo" />
            : null}
        </div> */}
        {/* <div className="hr">--------------------------------------------------------------------------------------</div>
        <div className="power">
          <a href="https://wellbyqr.com"><strong>wellbyqr.com</strong></a>
        </div> */}
        {/*  <div style={{ borderBottom: '1px dashed #787878', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
           <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/logo_new.png`} alt="" style={{ "width": '120px', "textAlign": 'center', marginTop: '50px' }} className="icon" />
           <hr className={`${style.footerHr}`} style={{ borderTop: '1px dashed #787878', width: '90%', margin: '5px' }} />
           <div style={{ margin: '0 0 10px 0', fontSize: '12px' }}><span style={{ color: '#787878' }}> Powered by:</span> <span>Wellby Solutions Pvt. Ltd.</span></div>
         </div> */}
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/wellbylogo/footer_logo.png`} alt="" style={{ "width": '120px', "textAlign": 'center', marginTop: '50px' }} className="icon" />
        <hr className={`${style.footerHr}`} style={{ borderBottom: '.01rem dashed #787878', width: '100%', margin: '5px' }} />
        <div style={{ margin: '0 0 10px 0', fontSize: '12px' }}>
        {/* <div style={{ color: '#787878' }}>Copyright © 2024. All Rights Reserved.</div> */}
          <span style={{ color: '#787878' }}> 
         Powered by:</span> 
        <span>Wellby Solutions Pvt. Ltd.</span></div>
      </div>
      </section>

      <section className="shareDetailsOverlay" id="overlayTwo">
        <div className="dvShareDetails">
          <div className="dvLogoHolder">
            <div className="dvLogo1">
              <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/logoNew.png`} alt="" className="imgLogoNew d-none" />
            </div>
            <div className="vr">&nbsp;</div>
            <div className="dvLogo2">
              <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/logo2.png`} alt="" className="imgLogo2 d-none" />
            </div>
          </div>
          <div className="dvShareDetailsContent">
            <div className="btnHolder">
              <button className="btnClose" id="closeSlider"
                onClick={() => {
                  $('#overlayTwo, .dvMessage').fadeOut();
                  $(`#${swipeStyle['slider']}`).animate({ left: '-10px' }, 300);
                  $(`.${swipeStyle['slide-text']}`).fadeTo(300, 1);
                  $('body').css('overflow', 'auto');
                }}><span>
                  CLOSE </span>&#10005;</button>
            </div>
            <div className="dvShareDetailsContent1">
              {/* <a href="tel:6177787312"></a>  */}
              <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/user.png`} alt="" className="imgUser" />
              <h2 className="heading">Share My Details</h2>
              <h4>with the Emergency Contacts
              </h4>
              <span className="orgBrd50"></span>
              <p>Your details are secured and will be shared with the <strong> Emergency Contacts</strong>
                only.</p>
              <div className="dvShareForm">
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-prepend">
                      <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/name.png`} alt="" className="icon" />
                    </div>
                    <div className="vrOrg">&nbsp;</div>
                    <input type="text" className="form-control" placeholder="ENTER YOUR NAME" onChange={(e) => setName(e.target.value)} />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-prepend">
                      <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/mobile.png`} alt="" className="icon" />
                    </div>
                    <div className="vrOrg" style={{ "paddingLeft": '8px', 'paddingRight': '5px' }}> +91 </div>
                    <input type="text" className="form-control" maxLength="10"
                      placeholder="ENTER YOUR MOBILE NUMBER" onChange={(e) => setPhone(e.target.value)} />
                  </div>
                </div>
                <div className="checkbox-group">
                  <label className="container lblCheckbox">Share my <strong>Current Location</strong>
                    <input type="checkbox" checked="checked" onChange={() => {

                    }} />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <button className="nameHeading"
                  onClick={() => callSOS()}>Confirm</button>
                <span className={`error ${scannedProfileStyle['error']}`}>
                  {sos_error}
                </span>
              </div>
            </div>
            <div className="dvShareOTP">
              <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/otp.png`} alt="" className="imgUser" />
              <h2 className="heading">Enter Verification Code</h2>
              <p>We have sent an OTP on your mobile number
                <br /> <strong>+91 {phone}</strong>
              </p>
              <span className="orgBrd50"></span>
              <h4 className="txtBlue">Change Phone Number?</h4>
              <div className="dvOtp">
                <input type="text" size="2" onKeyUp={(e) => movetoNext(e, 'second')} id="first" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    otp[0] = e.target.value;
                    setOTP(otp)
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[0] = '';
                      setOTP(otp)
                      document.getElementById('first').focus();
                    }
                  }} />
                <input type="text" size="2" onKeyUp={(e) => movetoNext(e, 'third')} id="second" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    otp[1] = e.target.value;
                    setOTP(otp)
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[1] = '';
                      setOTP(otp)
                      document.getElementById('first').focus();
                    }
                  }} />
                <input type="text" size="2" onKeyUp={(e) => movetoNext(e, 'fourth')} id="third" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    otp[2] = e.target.value;
                    setOTP(otp)
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[2] = '';
                      setOTP(otp)
                      document.getElementById('second').focus();
                    }
                  }} />
                <input type="text" size="2" onKeyUp={(e) => movetoNext(e, 'fifth')} id="fourth" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    otp[3] = e.target.value;
                    setOTP(otp)
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[3] = '';
                      setOTP(otp)
                      document.getElementById('third').focus();
                    }
                  }} />
                <input type="text" size="2" onKeyUp={(e) => movetoNext(e, 'sixth')} id="fifth" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    otp[4] = e.target.value;
                    setOTP(otp)
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[4] = '';
                      setOTP(otp)
                      document.getElementById('fourth').focus();
                    }
                  }} />
                <input type="text" size="2" id="sixth" maxLength="1" className="otp-form-control" onChange={(e) => {
                  otp[5] = e.target.value;
                  setOTP(otp)
                }} onKeyDown={(e) => {
                  if (e.keyCode === 8) {
                    otp[5] = '';
                    setOTP(otp);
                    document.getElementById('sixth').value = '';
                    document.getElementById('fifth').focus();
                  }
                }} />
              </div>
              <button className="btnResendOtp">
                OTP not received? 
                <span> Resend OTP</span>
              </button>
              <button className="nameHeading"
                onClick={() => verifySOSOTP()}>Verify</button>
              <span className={`error ${scannedProfileStyle['error']}`}>
                {verify_error}
              </span>
            </div>
          </div>
        </div>
        <div className="dvMessage">
          <div className="imgHolder">
            <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/sent.png`} alt="" className="imageSent" />
          </div>
          <h2 className="heading">
            <span className="txtBlack">Message Sent </span>Successfully!
          </h2>
          <div className="alCenter">
            <span className="orgBrd50"></span>
          </div>
          <h3 className="name">
            <strong>
              {profile.name}'s<br />
              Emergency Contacts
            </strong>
            have been informed.
          </h3>
          <h3 className="thankU">
            <strong>THANK YOU</strong>
            FOR YOUR SUPPORT
          </h3>
          <button className="nameHeading"
            onClick={() => {
              $('#overlayTwo, .dvMessage').fadeOut();
              $(`#${swipeStyle['slider']}`).animate({ left: '-10px' }, 300);
              $(`.${swipeStyle['slide-text']}`).fadeTo(300, 1);
              $('body').css('overflow', 'auto');
            }}>OK</button>
        </div>
      </section>

    </>
  );
}

export default DefaultScannedProfile;
