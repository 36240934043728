import React from 'react';
import style from './Loading.module.css';
import { useSelector } from 'react-redux';
const Loading = () => {
	const showLoading = useSelector((state) => state.showLoading);
	return (
		<>
			{
			showLoading ? <div className={`${style['center-screen']}`}>
				<div className={`${style["lds-ripple"]}`}><div></div><div></div></div>
			</div> : null
			}
		</>
	)
}

export default Loading;