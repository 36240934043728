const initialState = null;
const updateToken = (state = initialState, action) => {
	switch (action.type) {
		case "SET_TOKEN":
			return action.data;
		default:
			return state;
	}
}

export default updateToken;