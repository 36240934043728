import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ProfilePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const profileData = location.state?.formData || {};

  const handleEdit = () => {
    navigate('/edit-form'); // Replace with your actual edit form path
  };

  return (
    <div style={{ margin: '20px' }}>
      <h1>Profile Details</h1>
      <div>
        <h2>Personal Details</h2>
        <p><strong>First Name:</strong> {profileData.firstName}</p>
        <p><strong>Middle Name:</strong> {profileData.middleName}</p>
        <p><strong>Last Name:</strong> {profileData.lastName}</p>
        <p><strong>Mobile Number:</strong> {profileData.mobile}</p>
        <p><strong>Email Address:</strong> {profileData.emailId}</p>
        <p><strong>Address:</strong> {profileData.address}</p>
        <p><strong>Landmark:</strong> {profileData.landmark}</p>
        <p><strong>City:</strong> {profileData.city}</p>
        <p><strong>State:</strong> {profileData.state}</p>
        <p><strong>Pincode:</strong> {profileData.pincode}</p>
      </div>
      <div>
        <h2>Medical Details</h2>
        <p><strong>Blood Group:</strong> {profileData.bloodGroup}</p>
        <p><strong>Allergies:</strong> {profileData.allergies}</p>
        <p><strong>Medical Conditions:</strong> {profileData.medicalConditions}</p>
        <p><strong>Primary Physician Name:</strong> {profileData.primaryPhysicianName}</p>
        <p><strong>Primary Physician Mobile:</strong> {profileData.primaryPhysicianMobile}</p>
      </div>
      <div>
        <h2>Insurance Details</h2>
        <p><strong>Mediclaim Policy Company Name:</strong> {profileData.mediclaimPolicyCompanyName}</p>
        <p><strong>Mediclaim Policy ID:</strong> {profileData.medicliamPolicyId}</p>
        <p><strong>Mediclaim Policy Expiry Date:</strong> {profileData.mediclaimPolicyExpiryDate}</p>
        {profileData.filePreview && (
          <div>
            <h3>Uploaded Document</h3>
            <img src={profileData.filePreview} alt="Uploaded Document" style={{ width: '100%', height: 'auto' }} />
          </div>
        )}
      </div>
      <button
        onClick={handleEdit}
        style={{ marginTop: '20px', padding: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }}
      >
        Edit
      </button>
    </div>
  );
};

export default ProfilePage;
