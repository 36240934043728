const initialState = null;
const updateTemplateCode = (state = initialState, action) => {
	switch (action.type) {
		case "SET_TEMPLATE_CODE":
			return action.data;
		default:
			return state;
	}
}

export default updateTemplateCode;