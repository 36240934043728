import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { submitProfileData } from '../../../services/api.services'

const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [fileData, setFileData] = useState(null);
  const [formData, setFormData] = useState({
    step1: { first_name: '', middle_name: '', last_name: '', mobile: '', email: '', address: '', city: '', state: '', country: '', zipcode: '', landmark: '' },
    step2: { primaryContactName: '', primaryContactMobile: '', secondaryContactName: '', secondaryContactMobile: '', policy_relationship_manager_name: '', policy_relationship_manager_mobile: '', policy_tollfree_number: '' },
    step3: { blood_group: '', allergies: '', medical_condition: '', primary_physician_name: '', primary_physician_mobile: '' },
    step4: { medicalaim_policy_personal_company: '', medicalaim_policy_personal_id: '', medicalaim_policy_personal_expiry: '', file: null, filePreview: null }
  });
  
  const navigate = useNavigate();

  const { token_code } = useParams();
  const handleChange = (step, event) => {
    const { name, value, files } = event.target;
    if (name === 'file') {
      const file = files[0];
      setFileData(files[0])
      const filePreview = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        [step]: {
          ...prevData[step],
          file,
          filePreview
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [step]: {
          ...prevData[step],
          [name]: value,
        },
      }));
    }
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };
  const token = token_code;
  const  handleSubmit = async () => {
  let completeFormData = new FormData()
    const { primaryContactName, primaryContactMobile, secondaryContactName, secondaryContactMobile ,policy_relationship_manager_name,policy_relationship_manager_mobile} = formData.step2;
    const data = {
        company_code: 'store',
        token:token,
        ...formData.step1,
        contacts: [
            {
              contact_first_name: primaryContactName,
              contact_mobile: primaryContactMobile
            },
            {
              contact_first_name: secondaryContactName,
              contact_mobile: secondaryContactMobile
            }
          ],
          policy_relationship_manager_mobile,
          policy_relationship_manager_name,
        ...formData.step3,
        ...formData.step4
      };

      Object.keys(data).forEach(elm=>{
        completeFormData.append(elm,data[elm])
        completeFormData.append("file",fileData)
      })
  
      // Here you can send the completeFormData to your server or handle it as needed
      console.log('Complete Form Data:', completeFormData);
  
      try {
        let result = await submitProfileData(completeFormData)
        alert('Your details have been updated successfully!');
        navigate('/sos_profile/profile');
      } catch (error) {
        console.error('Submission error:', error);
      }
  };

  const steps = [
    <Step1 data={formData.step1} handleChange={(e) => handleChange('step1', e)} />,
    <Step2 data={formData.step2} handleChange={(e) => handleChange('step2', e)} />,
    <Step3 data={formData.step3} handleChange={(e) => handleChange('step3', e)} />,
    <Step4 data={formData.step4} handleChange={(e) => handleChange('step4', e)} />,
  ];

  return (
    <div style={{ margin: '20px' }}>
      {steps[currentStep]}
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0', padding: '10px' }}>
        {currentStep > 0 && <button style={buttonStyle} onClick={prevStep}>Previous</button>}
        {currentStep < steps.length - 1 && <button style={buttonStyle} onClick={nextStep}>Next</button>}
        {currentStep === steps.length - 1 && <button style={buttonStyle} onClick={handleSubmit}>Submit</button>}
      </div>
    </div>
  );
};

const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px'
};

const Step1 = ({ data, handleChange }) => (
  <>
    <h3 style={{ textAlign: 'center', marginTop: '50px', backgroundColor: '#ff9e00', color: '#fff', padding: '10px', borderRadius: '5px' }}>Personal Details</h3>
    <div>
      {renderInput('First Name', 'first_name', data.first_name, handleChange)}
      {renderInput('Middle Name', 'middle_name', data.middle_name, handleChange)}
      {renderInput('Last Name', 'last_name', data.last_name, handleChange)}
      {renderInput('Mobile Number', 'mobile', data.mobile, handleChange)}
      {renderInput('Email Address', 'email', data.email, handleChange)}
      {renderInput('Address', 'address', data.address, handleChange)}
      {renderInput('Landmark', 'landmark', data.landmark, handleChange)}
      {renderInput('City', 'city', data.city, handleChange)}
      {renderInput('State', 'state', data.state, handleChange)}
      {renderInput('Pincode', 'zipcode', data.pincode, handleChange)}
    </div>
  </>
);

const Step2 = ({ data, handleChange }) => (
  <>
    <h3 style={{ textAlign: 'center', marginTop: '50px', backgroundColor: '#ff9e00', color: '#fff', padding: '10px', borderRadius: '5px' }}>Emergency Contact Details</h3>
    <div>
      {renderInput('Primary Contact Name', 'primaryContactName', data.primaryContactName, handleChange)}
      {renderInput('Primary Contact Mobile', 'primaryContactMobile', data.primaryContactMobile, handleChange)}
      {renderInput('Secondary Contact Name', 'secondaryContactName', data.secondaryContactName, handleChange)}
      {renderInput('Secondary Contact Mobile', 'secondaryContactMobile', data.secondaryContactMobile, handleChange)}
      {renderInput('Policy Relationship Manager Name', 'policy_relationship_manager_name', data.policy_relationship_manager_name, handleChange)}
      {renderInput('Policy Relationship Manager Contact', 'policy_relationship_manager_mobile', data.policy_relationship_manager_mobile, handleChange)}
      {renderInput('Toll-Free Number', 'policy_tollfree_number', data.policy_tollfree_number, handleChange)}
    </div>
  </>
);

const Step3 = ({ data, handleChange }) => (
  <>
    <h3 style={{ textAlign: 'center', marginTop: '50px', backgroundColor: '#ff9e00', color: '#fff', padding: '10px', borderRadius: '5px' }}>Medical Details</h3>
    <div>
      {renderInput('Blood Group', 'blood_group', data.blood_group, handleChange)}
      {renderInput('Allergies', 'allergies', data.allergies, handleChange)}
      {renderInput('Medical Conditions', 'medical_condition', data.medical_condition, handleChange)}
      {renderInput('Primary Physician Name', 'primary_physician_name', data.primary_physician_name, handleChange)}
      {renderInput('Primary Physician Mobile', 'primary_physician_mobile', data.primary_physician_mobile, handleChange)}
    </div>
  </>
);

const Step4 = ({ data, handleChange }) => (
  <>
    <h3 style={{ textAlign: 'center', marginTop: '50px', backgroundColor: '#ff9e00', color: '#fff', padding: '10px', borderRadius: '5px' }}>Mediclaim Policy Details</h3>
    <div>
      {renderInput('Mediclaim Policy Company Name', 'medicalaim_policy_personal_company', data.medicalaim_policy_personal_company, handleChange)}
      {renderInput('Mediclaim Policy ID', 'medicalaim_policy_personal_id', data.medicalaim_policy_personal_id, handleChange)}
      <div style={{ margin: '5px' }}>
        <small style={{ fontSize: '0.8rem' }}>Mediclaim Policy Expiry Date</small>
        <input
          type="date"
          name="medicalaim_policy_personal_expiry"
          style={{
            width: '100%',
            padding: '10px',
            margin: '5px 0',
            boxSizing: 'border-box',
            borderRadius: '5px',
            border: 'none'
          }}
          value={data.medicalaim_policy_personal_expiry}
          onChange={handleChange}
        />
      </div>
      <div style={{ margin: '5px' }}>
        <small style={{ fontSize: '0.8rem' }}>Upload Document</small>
        <input
          type="file"
          name="file"
          style={{
            width: '100%',
            padding: '10px',
            margin: '5px 0',
            boxSizing: 'border-box',
          }}
          onChange={handleChange}
        />
        {data.filePreview && (
          <div style={{ marginTop: '10px' }}>
            <small style={{ fontSize: '0.8rem' }}>Preview:</small>
            <img src={data.filePreview} alt="Preview" style={{ width: '100%', height: 'auto', marginTop: '5px' }} />
          </div>
        )}
      </div>
    </div>
  </>
);

const renderInput = (label, name, value, handleChange) => (
  <div style={{ margin: '5px' }}>
    <small style={{ fontSize: '0.8rem' }}>{label}</small>
    <input
      type="text"
      name={name}
      style={{
        width: '100%',
        padding: '10px',
        margin: '5px 0',
        boxSizing: 'border-box',
        border: 'none',
        borderRadius: '5px'
      }}
      placeholder={'Enter '+label}
      value={value}
      onChange={handleChange}
    />
  </div>
);

export default MultiStepForm;
