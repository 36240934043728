import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setTemplateCode } from '../../core/redux/actions';
import $ from 'jquery';
import homeStyle from './Home.module.css';
function Home() {
  const dispatch = useDispatch();
  useState(() => {
    dispatch(setTemplateCode('default'));
  }, []);
  useState(()=>{
    $('.mainHolder').css('padding-top','0px');
  },[])
  return (
    <>
      {/* <div className={`${homeStyle['logo']}`}>
        <img src="/assets/images/logo_new.png" alt="" />
      </div> */}
      {/* <button onClick={() => {
        $('#sos_call')[0].click();
      }}>Call</button>
      <a href="tel:8107095482" id="sos_call"></a> */}

      <div className={`${homeStyle['card']}`} style={{display: 'none'}}>
        <div className={`${homeStyle['header']}`}>
          <div className={`${homeStyle["logo"]}`}>
            <a href=".">
            <img src="/assets/images/logo_new.png" alt="" />
            </a>
          </div>
          <div className={`${homeStyle["social"]}`}>
            {/* <a href="https://facebook.com" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://twitter.com" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
            <a href="https://github.com/arkn98/coming-soon" title="GitHub" target="_blank" rel="noopener noreferrer">
              <IconGithub className="icon" />
            </a> */}
          </div>
        </div>
        <div className={`${homeStyle["content"]}`}>
          <div className={`${homeStyle["title-holder"]}`}>
            <h1>Get ready for the change.</h1>
            <p>Website coming soon. Please check back to know more. Shoot us an email if you're curious.</p>
          </div>
          {/* <a href="mailto:user@example.com">
            <div className="cta">Send us an email</div>
          </a> */}
        </div>
        <div className="footer">
          {/* <span>made by <a className="underlined" href="https://github.com/arkn98" target="_blank" rel="noopener noreferrer">arkn98</a> using <a className="underlined" href="https://reactjs.org/" title="ReactJS" target="_blank" rel="noopener noreferrer">React</a> | <a className="underlined" href="https://github.com/arkn98/coming-soon" title="GitHub repo" target="_blank" rel="noopener noreferrer">GitHub</a></span> */}
        </div>
      </div>

      <div style={{display: 'flex','overflow-x':'hidden', flexDirection: 'column', backgroundColor: '#d9dddd',maxWidth:'415px', height: '100vh'}}>
        <div className="banner" style={{position:'relative',display:'flex',flexDirection:'row',maxWidth:'415px',margin:'0 auto'}}>
          <img src="/assets/images/landing/desktop_wellbyqr_logo.png" alt=""  className="img-fluid" style={{position:'absolute',top:'25px',left:'20px',width:'30%'}}/>
          <img src="/assets/images/landing/mobile_banner.png" alt=""  className="img-fluid" style={{maxWidth:'400px',margin:'-2px 58px',height:'90%', "width": "calc(100% - 3%)"}}/>
        </div>
        
        <div style={{marginLeft:'62px',marginBottom:'40px' }}>
        <img src="/assets/images/landing/text.png" alt=""  className="img-fluid" style={{width:'80%'}}/>

          {/* <p className="" style={{color:'#2E3D46',fontWeight:'200',fontSize:'24px',fontStyle:'italic',fontStretch:'semi-expanded'}}>Hey Guys!</p>
          <p style={{color:'#273741'}}>we're Coming Soon...</p><br/>
          <small style={{color:'#273741'}}>with a holistic approach towards <br/> the <strong style={{fontWeight:'bold'}}>Safety of an Individual</strong></small> */}
        </div>
        <div style={{backgroundColor:'#fff', height:'200px',padding:'25px 0'}}>
          <small style={{color:'#273741',fontSize:'14px',fontWeight:'500',marginLeft:'30px'}}>You can reach us at</small>
          <p style={{color:'#273741',fontSize:'12px',fontWeight:'300',marginLeft:'30px',marginTop:'2px',display:'flex',justifyItems:'center'}}>
          <img src="/assets/images/landing/email_icon.png" alt="" style={{width:'20px',marginRight:'5px'}}/> conect@wellbyindia.com
          </p>
          <p style={{borderBottom:'1.3px solid #79B352', width:'85%',textAlign:'center',margin:'10px auto'}}></p>
         <p style={{color:'#273741',fontSize:'13px',fontWeight:'600',marginLeft:'30px'}}>Wellby Solutions Pvt. Ltd</p>
        </div>
      </div>
      
      
      
    </>
  )
}

export default Home;