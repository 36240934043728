import React, { useState, useEffect } from "react";
import { getprofile } from "../../../services/api.services";
import { useDispatch, useSelector } from "react-redux";
import { updateLoaderStatus } from "../../../core/redux/actions";
import { useNavigate, useParams } from "react-router-dom";
import validator from "validator"; // Import validator for email validation
import { useCookies } from 'react-cookie';
import { tokenRegister, checkToken, sendOtp, verifyStoreOTP, } from "../../../services/api.services";
import style from "./SosProfile.module.css";
import $ from 'jquery';
import { Link } from "react-router-dom";


import Constants from "../../../core/Constants";
import MultiStepForm from "./MultiStepForm";

function SOSProfile() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState({ name: false, email: false, mobile: false });
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate(); // Use useNavigate for navigation
  const { token_code } = useParams();
  const [isRegistered, setIsRegistered] = useState(false);
  const [profile, setProfile] = useState({});
  const [otp, setOTP] = useState(['', '', '', '', '', '']);
  const [validOTP, setValidOTP] = useState('');
  const [verify_error, setVerifyError] = useState('');
  const [otpId, setOTPId] = useState('');
  // const [currentStep, setCurrentStep] = useState(0);

  // const [formData, setFormData] = useState({
  //   step1: { firstName: '', middleName: '', lastName:'', mobile: '' ,emailId:'', address: '', city: '', state: '', country: '', pincode: '' ,landmark:'',},
  //   step2: { primaryContactName: '', primaryContactMobile: '', secondaryContactName: '', secondaryContactMobile: '', policyRelationshipManagerName: '', policyRelationshipManagerContact: '', tollFreeNumber: '' },
  //   step3: { bloodGroup: '', allergies: '', medicalConditions: '', primaryPhysicianName: '', primaryPhysicianMobile: ''},
  //   step4: { mediclaimPolicyCompanyName: '', medicliamPolicyId: '', medicliamPolicyExpiryDate: '' }
  // });

  const movetoNext = (current, nextFieldID) => {
    if (current.target.value.length >= current.target.maxLength) {
      document.getElementById(nextFieldID).focus();
    }
  }

  const verifyLoginOTP = async () => {
    console.log(otpId.toString(), otp.join(''));
    let result = await verifyStoreOTP(otpId.toString(), otp.join(''));
    if (result.data.status) {
      $('.dvLoginDetails').fadeOut();
      $('#registerForm').fadeIn(0);
      $('.dvLoginOTP').css('left', '100vw')
      const token = token_code;
      let result = await tokenRegister({ name, email, mobile, token });
      if (result.data.status) {
        setError('');
        // alert('Profile Registered Successfully.');
        setTimeout(() => {
          window.location.replace(`/sos_profile/${token}`);
        }, 1000);
      } else {
        setError(result.data.message);
      }
      setSubmitted(true);
      setError(prev => ({ ...prev, form: false }));

    } else {
      setVerifyError(result.data.message);
    }
  }

  // Handling the name change
  const handleName = (e) => {
    setName(e.target.value);
    setSubmitted(false);
    if (!/^[a-zA-Z\s]*$/.test(e.target.value)) {
      setError(prev => ({ ...prev, name: true }));
    } else {
      setError(prev => ({ ...prev, name: false }));
    }
  };
  // Handling the email change
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setSubmitted(false);
    if (!validator.isEmail(e.target.value)) {
      setError(prev => ({ ...prev, email: true }));
    } else {
      setError(prev => ({ ...prev, email: false }));
    }
  };
  // Handling the mobile change
  const handleMobile = (e) => {
    setMobile(e.target.value);
    setSubmitted(false);
    if (!/^\d{10}$/.test(e.target.value)) {
      setError(prev => ({ ...prev, mobile: true }));
    } else {
      setError(prev => ({ ...prev, mobile: false }));
    }
  };

  // Handling the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name === "" || email === "" || mobile === "" || error.name || error.email || error.mobile) {
      setError(prev => ({ ...prev, form: true }));
    } else {
      try {
        let sendOtpStatus = await sendOtp(mobile);
        if (sendOtpStatus.data.status) {
          $('#registerForm').fadeOut(300);
          $('.dvLoginOTP').fadeIn(0).animate({ 'left': '0px' }, 500);
          $('.otp-form-control').val('');
          setOTPId(sendOtpStatus.data.data.otp_id);
          verifyLoginOTP();

        } else {
          alert('Something went wrong. Please try again later.');
        }

      } catch (err) {
        console.error("There was an error registering the user!", error);
        setError(prev => ({ ...prev, form: true }));
      }

    }
  };

  // Showing the success message
  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? "" : "none",
        }}>
        <strong style={{ color: "green", fontSize: '12px' }}>Congratulations {name}, you are successfully registered!!</strong>
      </div>
    );
  };

  // Showing the error message
  const errorMessage = () => {
    return (
      <div
        className="error"
        style={{
          display: error.form ? "" : "none",
        }}>
        <small>Please fill the valid details in the form.</small>
      </div>
    );
  };

  const checkRegisteration = async () => {
    let result = await checkToken(token_code);
    if (result.data.status) {
      const registered = true;
      setIsRegistered(registered);
      setProfile(result.data.data);
    } else {
      console.log("Token not found");
      setIsRegistered(false);
    }
  };


  useEffect(() => {
    
    checkRegisteration();
  }, []);



  return (
    <>
      {/* Top Header banner */}
      <div>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '40px' }}>
          <img src={Constants.PRE_ASSETS_PATH + "/assets/images/landing/desktop_wellbyqr_logo.png"} alt="" width="60%" />
        </div>

        {isRegistered ? (
          <div>
            {/* <div className={`card ${style.profileCard}`}>
              <div className="card-body" style={{ postion: 'relative', padding: '0' }}>
                <label> Welcome,</label>
                <div className={`${style.profileName}`}>
                  {profile.contact_name}
                </div>
                <span className={`${style.message}`}>It seems your contact has verified as your Emergency Connect. Always keep your Health, Medical and Emergency Contact details updated.</span>
                <div className={`${style.checkEmergencyContactVerified}`}><span style={{ "fontSize": '15px' }}>Emergency Contact Verified</span></div>
                <hr className={`${style.hr}`} />
                <div>
                  <div className={`inline-block ${style.profileLastUpdated}`}><span style={{ "fontSize": '15px' }}>Profile Last Updated</span></div>
                  <div className={`inline-block ${style.profileLastUpdatedDate}`}><span style={{ "fontSize": '14px', "fontWeight": 'bold' }}>
                    {profile.last_updated || 'N/A'}
                  </span></div>
                </div>
              </div>
            </div> */}

           <MultiStepForm/>

          </div>
        ) : (
          <div className="form g-3" style={{ padding: '10px' }}>
            <div id="registerForm" style={{ marginTop: '20px' }}>
              <div style={{ "position": 'relative' }}>
                <div style={{ marginTop: '50px' }}>
                  <h1 style={{ margin: '30px 0 20px 0', textAlign: 'center' }}>Register</h1>
                  <p style={{ fontSize: '12px', margin: '10px' }}> Please fill out the registration form below to access your profile and start using our services.</p>
                  {/* <hr /> */}
                </div>
                <div className="messages" style={{ backgroundColor: 'transparent' }}>
                  {errorMessage()}
                  {successMessage()}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                  <div className={`${style['form-group']}`} style={{ marginBottom: '15px' }}>
                    <div className={`${style['input-group']}`} style={{ position: 'relative' }} >
                      <label className={`${style['form-control']}`} >
                        <i class="fas fa-user" style={{ position: 'absolute', top: '10px', right: '10px' }}></i>
                        <input type="text" className={`${style['form-control']}`} onChange={handleName} value={name}
                          placeholder="Enter your fullname" />
                        {error.name && <small className="error" >Only alphabets are allowed.</small>}
                      </label>
                    </div>
                  </div>

                  <div className={`${style['form-group']}`} style={{ marginBottom: '15px' }}>
                    <div className={`${style['input-group']}`} style={{ position: 'relative' }} >
                      <label className={`${style['form-control']}`} >
                        <i class="fas fa-envelope" style={{ position: 'absolute', top: '10px', right: '10px' }}></i>
                        <input type="text" className={`${style['form-control']}`} onChange={handleEmail} value={email}
                          placeholder="Enter your email address" />
                        {error.email && <small className="error">Please enter a valid email address.</small>}
                      </label>
                    </div>
                  </div>

                  <div className={`${style['form-group']}`} style={{ marginBottom: '15px' }}>
                    <div className={`${style['input-group']}`} style={{ position: 'relative' }} >
                      <label className={`${style['form-control']}`} >
                        <i class="fas fa-mobile" style={{ position: 'absolute', top: '10px', right: '10px' }}></i>
                        <input type="text" id="mobile" className={`${style['form-control']}`} onChange={handleMobile} value={mobile}
                          placeholder="Enter your mobile" />
                      </label>
                      {error.mobile && <small className="error" style={{ marginTop: '15px' }}>Mobile number must be 10 digits.</small>}
                    </div>
                  </div>
                </div>

                <div style={{ textAlign: 'center', margin: '40px 0 20px 0' }}>
                  <button className={`${style.saveUpdateBtn}`} onClick={handleSubmit} type="submit"> REGISTER </button>
                </div>
              </div>
            </div>

            <div className="dvLoginOTP" style={{ "position": 'relative', marginTop: '100px' }}>
              {/* <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/otp.png`} alt="" className="imgUser" /> */}
              <h2 className="login-heading" style={{ "fontSize": '1.9rem', "display": 'block' }}>Enter Your <br />Verification Code </h2>
              <p style={{ "fontSize": '.85rem', margin: '15px 0' }}>We have sent an OTP on your mobile number </p>
              <strong style={{ "fontSize": '1.12rem', margin: '40px 0' }}>+91 {mobile}</strong>

              <small className="txtBlue"
                onClick={() => {
                  $('#registerForm').fadeIn(300);
                  $('.dvLoginDetailsContent1').css('left', '0');
                  $('.dvLoginOTP').fadeOut(0);
                  $('body').css('overflow', 'hidden');
                  $('#locker').text('lock_outline');
                }}
                style={{ "color": '#1068B2', fontSize: '12px', "margin": '10px 0 0 10px ', cursor: 'pointer' }} >Change Phone Number?</small>
              <div className="dvOtp" style={{ marginTop: '40px' }}>
                <input style={{ "fontSize": '2.8rem' }}
                  type="number" size="2"
                  onKeyUp={(e) => movetoNext(e, 'second')}
                  id="first" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    if (e.target.value.length > 1) { e.target.value = e.target.value.slice(0, 1); }
                    otp[0] = e.target.value;
                    setOTP(otp);
                    setValidOTP(otp.join(''));
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[0] = '';
                      setOTP(otp);
                      setValidOTP(otp.join(''));
                      document.getElementById('first').focus();
                    }
                  }} />
                <input style={{ "fontSize": '2.8rem' }} type="number" size="2"
                  onKeyUp={(e) => movetoNext(e, 'third')} id="second" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    if (e.target.value.length > 1) { e.target.value = e.target.value.slice(0, 1); }
                    otp[1] = e.target.value;
                    setOTP(otp)
                    setValidOTP(otp.join(''));
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[1] = '';
                      setOTP(otp)
                      setValidOTP(otp.join(''));
                      document.getElementById('first').focus();
                    }
                  }} />
                <input style={{ "fontSize": '2.8rem' }} type="number" size="2"
                  onKeyUp={(e) => movetoNext(e, 'fourth')} id="third" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    if (e.target.value.length > 1) { e.target.value = e.target.value.slice(0, 1); }
                    otp[2] = e.target.value;
                    setOTP(otp)
                    setValidOTP(otp.join(''));
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[2] = '';
                      setOTP(otp)
                      setValidOTP(otp.join(''));
                      document.getElementById('second').focus();
                    }
                  }} />
                <input style={{ "fontSize": '2.8rem' }} type="number" size="2"
                  onKeyUp={(e) => movetoNext(e, 'fifth')} id="fourth" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    if (e.target.value.length > 1) { e.target.value = e.target.value.slice(0, 1); }
                    otp[3] = e.target.value;
                    setOTP(otp)
                    setValidOTP(otp.join(''));
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[3] = '';
                      setOTP(otp)
                      setValidOTP(otp.join(''));
                      document.getElementById('third').focus();
                    }
                  }} />
                <input style={{ "fontSize": '2.8rem' }} type="number" size="2"
                  onKeyUp={(e) => movetoNext(e, 'sixth')}
                  id="fifth" maxLength="1"
                  className="otp-form-control" onChange={(e) => {
                    if (e.target.value.length > 1) { e.target.value = e.target.value.slice(0, 1); }
                    otp[4] = e.target.value;
                    setOTP(otp)
                    setValidOTP(otp.join(''));
                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {
                      otp[4] = '';
                      setOTP(otp)
                      setValidOTP(otp.join(''));
                      document.getElementById('fourth').focus();
                    }
                  }} />
                <input style={{ "fontSize": '2.8rem' }} type="number" size="2"
                  id="sixth" maxLength="1"
                  className="otp-form-control"

                  onChange={(e) => {
                    if (e.target.value.length > 1) {
                      e.target.value = e.target.value.slice(0, 1);
                    }
                    if (e.target.value.length === 1) {

                      e.target.blur();
                    }
                    // if(e.target.value.length === 1){
                    //   console.log(e.target.value)
                    //   document.getElementById('sixth').blur();
                    // }
                    otp[5] = e.target.value;
                    setOTP(otp);
                    setValidOTP(otp.join(''));


                  }} onKeyDown={(e) => {
                    if (e.keyCode === 8) {

                      if (e.key === 'Backspace') {
                        e.target.value = '';
                      }
                      otp[5] = '';
                      setOTP(otp);
                      setValidOTP(otp.join(''));
                      document.getElementById('fifth').focus();

                    }
                  }} />
              </div>

              <button className="btnResendOtp" style={{ fontSize: '0.80rem', marginTop: '0' }}>
                OTP not received?
                <span>&nbsp; Resend OTP</span>
              </button>
              {
                validOTP.length === 6 ?
                  <button className="nameHeading"
                    id='verifyBtn' onClick={() => verifyLoginOTP()} style={{ cursor: 'pointer' }} >Verify</button> : null
              }
              <span style={{ color: 'red' }}>
                {verify_error}
              </span>
            </div>

          </div>
        )}
      </div>

      <div style={{
        position: 'absolute',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'

      }}>
        <img
          src={`${Constants.PRE_ASSETS_PATH}/assets/images/wellbylogo/footer_logo.png`}
          alt=""
          style={{
            width: '120px',
            textAlign: 'center',
            marginTop: '10px'
          }}
          className="icon"
        />
        <hr
          className={`${style.footerHr}`}
          style={{
            borderBottom: '.01rem dashed #787878',
            width: '100%',
            margin: '5px'
          }}
        />
        <div style={{
          margin: '0 0 5px 0',
          fontSize: '12px'
        }}>
          {/* <div style={{ color: '#787878' }}>Copyright © 2024. All Rights Reserved.</div> */}
          <span style={{ color: '#787878' }}>
            Powered by:</span>
          <small>Wellby Solutions Pvt. Ltd.</small>
        </div>
      </div>

    </>
  );
}

export default SOSProfile;
