import React from "react";
import style from './Loader.module.css';

function Loader() {
  return (
    <>
      <div className={style.loading}></div></>
  )
}

export default Loader