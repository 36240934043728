import Cookies from 'js-cookie';

class Constants {
  static HOST = process.env.REACT_APP_API_HOST;
  static get APIHeader() {
    let token = Cookies.get('Token') || localStorage.getItem('Token');
    if (token === undefined) {
      token = '';
    }

    return {
      'Content-Type': 'application/json',
      'API_KEY': process.env.REACT_APP_API_KEY,
      'Authorization': 'Bearer ' + token,
    }
  }

  static get FileAPIHeader() {
    let token = Cookies.get('Token') || localStorage.getItem('Token');
    if (token === undefined) {
      token = '';
    }

    return {
      'Content-Type': 'multipart/form-data',
      'API_KEY': process.env.REACT_APP_API_KEY,
      'Authorization': 'Bearer ' + token,
    }

  }

  static get PRE_ASSETS_PATH() {
    return process.env.REACT_APP_PRE_ASSETS_PATH || '';
  }

  static checkPhoneKey(e) {
    var a = [];
    var k = e.which;

    for (let i = 48; i < 58; i++)
      a.push(i);

    if (!(a.indexOf(k) >= 0))
      e.preventDefault();
  }

  static getFileName(fileNames) {
    if(!fileNames){
      return '';
    }
    var leftRightStrings = fileNames.split('.');
    //file name
    var fName = leftRightStrings[0];
    //file extension
    var fExtention = leftRightStrings[1];
    var lengthFname = fName.length;
    //if file name without extension contains more than 15 characters   
    if (lengthFname > 15) {
      return fName.substr(0, 10) + "..." + fName.substr(-5) + "." + fExtention;
    } else {
      return fileNames;
    }
  }
  static setUnKnownNumber(number) {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 15);
    const cookieValue = JSON.stringify({ number, expiry: now });
    Cookies.set('UnKonwnNumber', cookieValue);
  }
  static getUnKnownNumber() {
   return  Cookies.get('UnKonwnNumber');
  }
  static getUnknownNumberExpierd() {
    const cookieValue = Cookies.get('UnKonwnNumber');
    if(cookieValue === undefined){
      return true
    }
    const cookieData = JSON.parse(cookieValue);
    const expiryDate = new Date(cookieData.expiry);
    if (new Date() > expiryDate) {
      return true
    } else {
      return false
    }
  }
}

export default Constants;