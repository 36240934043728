import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import $ from 'jquery';
import { useDispatch, useSelector } from "react-redux";
import Header from "../../layout/header/Header";
import DefaultLogin from "../templates/default/login/Login";
import { setTemplateCode } from "../../core/redux/actions";

function Login() {
  const dispatch = useDispatch();
  const { type } = useParams();
  const templateCode = useSelector((state) => state.templateCode);
  const token = useSelector((state) => state.token);
  useEffect(() => {
    dispatch(setTemplateCode('default'));
  }, []);

  function getTemplate(templateCode) {
    if(!token){
      setTimeout(() => {
        $('.dvLoginDetailsContent1').css('left', '0');
        $('#loginOverlayTwo, .dvLoginDetails, .dvLoginDetailsContent, .dvLoginDetailsContent1').fadeIn(300);
        $('.dvLoginOTP').fadeOut(0);
        $('body').css('overflow', 'hidden');
        $('#locker').text('lock_outline');
        $('#mobileInput').val('');
      }, 500)
    }
    switch (templateCode) {
      default:
        return <>
        {/* <Header></Header> */}
          {/* <DefaultLogin type={type}></DefaultLogin> */}
        </>
    }
  }

  return (
    <>
      {
        getTemplate(templateCode)
      }
    </>
  )
}

export default Login;