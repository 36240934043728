

import { useEffect, useLayoutEffect, useState } from "react";
import style from "./Header.module.css";
import $ from 'jquery';
import Swipe from "../../../../layout/components/swipe/Swipe";
import { useLocation } from "react-router-dom";
import Constants from "../../../../core/Constants";
import swipeStyle from '../../../../layout/components/swipe/Swipe.module.css';

const AsianPaintHeader = () => {
  let location = useLocation();
  const [showSOS, setShowSOS] = useState(false);
  const [fixTop, setFixTop] = useState(false);
  const [fixmeTop, setFixmeTop] = useState(0);

  useLayoutEffect(() => {
    setFixmeTop($('.formDetails')?.offset()?.top || $(`.${style[`header`]}`).height() + 100);
    
  })
  useEffect(() => {
    // var fixmeTop = $('.formDetails').offset()?.top ||  500;   // get initial position of the element
    // let fixmeTop = $('.formDetails')?.offset()?.top || $(`.${style[`header`]}`).height() + 100;
    $(window).unbind("scroll");
    $(window).scroll(function () {            // assign scroll event listener
      if (!fixTop) {
        var currentScroll = $(window).scrollTop(); // get current position
        if (currentScroll >= fixmeTop) {           // apply position: fixed if you
          $(`.${style['header']}`).css({                      // scroll to that element or below it
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            right: '0'
          }).addClass(`${style['fix']}`);
          $('.mainHolder').css('padding-top', (fixmeTop + 100) + 'px');
          $('.swipeContainer').hide();
          $('.' + swipeStyle['dvSwipe']).hide();
        } else {                                   // apply position: static
          $(`.${style['header']}`).css({                      // if you scroll above it
            position: 'static'
          }).removeClass(`${style['fix']}`);
          $('.mainHolder').css('padding-top', '0px');
          $('.swipeContainer').show();
          $('.' + swipeStyle['dvSwipe']).show();
        }
      }
    });
  }, [fixmeTop]);

  useEffect(() => {
    $(document).ready(function() {
      setTimeout(() => {
        setFixmeTop($(`.${style[`header`]}`).height() + 100);  
      }, 1000);
      
    });
  },[]);
  useEffect(() => {
    if (fixTop) {
      $(window).unbind('scroll');
    }
  }, [fixTop]);

  useEffect(
    () => {
      const _fixTop = location.pathname.includes('/login', 0) || location.pathname.includes('/signup', 0);
      setShowSOS(location.pathname.includes('/ea/', 0));
      setFixTop(_fixTop);
    },
    [location]
  )
  return (

    <>
      <section className={`${style['header']}`} style={{ backgroundImage: `url(${Constants.PRE_ASSETS_PATH}/assets/images/header-bg-1.png)`, paddingTop: '80px' }}>
        <div className={`${style['mt-40']} ${fixTop ? style['fix'] : ''}`} style={{ backgroundImage: `url(${Constants.PRE_ASSETS_PATH}/assets/images/background.png)` }}></div>
        <div className={`${style['dvHeaderMenu']}`}>
          <span className={`${style['dvMenu']}`}
            onClick={() => {
              document.getElementById('overlay').style.display = 'block'; document.getElementsByTagName('BODY')[0].style.overflow = 'hidden'
            }}>
            <span className={style[`lineHolder`]}>
              <span className={`${style[`line`]} ${style[`line80`]}`}></span>
              <span className={style[`line`]}></span>
              <span className={`${style['line']} ${style['line60']}`}></span>
            </span>
          </span>
          <div className={`${style['safety']}`}>
            <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/safetyiseveryones.png`} alt="" className={`${style['safetyImage']}`} />
          </div>
          <div className={`${style['logo']}`}>
            <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/safetyiseveryones.png`} alt="" className={`${style['logoImage']}`} />
          </div>
        </div>
        <div className={`${style['dvLogo']}`}>
          <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/logo.png`} alt="" className={`${style["imgLogo"]}`} />
          <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/logoNew.png`} alt="" className={`${style['imgLogoNew']}`} />
          <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/qr.png`} alt="" className={`${style['imgQr']}`} />
          <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/logo2.png`} alt="" className="" />
        </div>
        <div className="swipeContainer">
          {
            showSOS ? <Swipe /> : null
          }
        </div>
      </section>
    </>
  );
};
export default AsianPaintHeader;
