const initialState = true;
const showLoading = (state = initialState, action) => {
	switch (action.type) {
		case "UPDATE_LOADER_STATUS":
			
			return action.data;
		default:
			return state;
	}
}

export default showLoading;