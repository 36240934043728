export const setToken = (data) => {
	return { type: "SET_TOKEN", data: data }
}

export const getToken = () => {
	return { type: "GET_TOKEN" }
}

export const updateLoaderStatus = (data) => {
	return { type: "UPDATE_LOADER_STATUS", data: data }
}

export const setTemplateCode = (data) =>{
	return { type: "SET_TEMPLATE_CODE", data: data }
}

export const updateLastScanProfileCode = (data) =>{
	return { type: "SET_LAST_SCAN_PROFILE_CODE", data: data }
}