import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import AsianPaintHeader from "../../pages/templates/asian_paints/header/Header";
import DefaultHeader from "../../pages/templates/default/header/Header";
import CorporateDefaultHeader from "../../pages/templates/corporate_default/header/Header";
import CorporateDefaultQRProfileHeader from "../../pages/templates/default_corporate_qr/header/Header";
import { useEffect, useState } from "react";

const Header = (props) => {
  let location = useLocation();
  const [showHeader,setShowHeader] = useState(false);
  // const templateCode = useSelector((state) => state.templateCode);
  const [templateCode, setTemplateCode] = useState('');
  useEffect(() => {
    setTemplateCode(props.templateCode);
  },[props.templateCode]);
  function getTemplate(templateCode) {
    switch (templateCode) {
      case 'asian_paints':
        return <AsianPaintHeader location={location}></AsianPaintHeader>
      case 'corporate_dafault':
        return <CorporateDefaultHeader location={location}></CorporateDefaultHeader>
      case 'abc_comp':
        return <CorporateDefaultQRProfileHeader location={location}></CorporateDefaultQRProfileHeader>
      default:
        return <DefaultHeader location={location}></DefaultHeader>
    }
  }
  useEffect(()=>{
    if(location.pathname !=='/'){
      setShowHeader(true);
    }else{
      setShowHeader(false);
    }
  },[location, location.pathname])
  return (
    <>
      {templateCode && showHeader?
        getTemplate(templateCode)
        : ''}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    templateCode: state.templateCode
  }
}
export default connect(mapStateToProps)(Header);
