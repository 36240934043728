
const initialState = true;
const setLastScanProfileCode = (state = initialState, action) => {
	switch (action.type) {
		case "SET_LAST_SCAN_PROFILE_CODE":
			return action.data;
		default:
			return state;
	}
}

export default setLastScanProfileCode;