import React, { useEffect } from "react";
import styleClass from './InActive.module.css'
import Constants from '../../core/Constants'
import $ from 'jquery';
function InActive() {
    useEffect(() => {
        // $('.shareDetailsOverlay').css('position', 'relative');

        $('.shareDetailsOverlay').css('display', 'block');
        $('.mainHolder').css('background-color', 'white');
    })

    return (
        <>
            <div >
                <img style={{ float: 'right', height: '40px', margin: '6%' }} src={`${Constants.PRE_ASSETS_PATH}/assets/images/wellbyqr_logo.png`}></img>
                <div className='shareDetailsOverlay' style={{ maxWidth: '414px', margin: '0 auto' ,background :'#D9DDDD' ,borderRadius:'20px 20px 0 0'}}>
                    <div className={`${styleClass['inactive-profile-image_header']}`}>
                        <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/inactive_image.png`}
                            className={`${styleClass['inactive-profile-image']}`}
                        ></img>
                    </div>
                    {/* rbc (29,191,60) */}
                    <div style={{ textAlign: 'center', marginTop: '6%' }}>
                        <h1 style={{ color: '#1DBF3C' ,marginBottom:'8px'}}>Ooops...</h1>
                        <p>Profile In-Active or </p>    
                        <p>Not Found!</p>
                    </div>
                    <div style={{ marginTop: '9%', textAlign: 'center' }}>
                        <span style={{ fontSize: '15px' }}>Contact your service provider.</span>
                    </div>
                    <div style={{  height: '30px', marginTop: '20%'}}>
                        <small style={{ color: '#273741', fontSize: '16px', fontWeight: '700', marginLeft: '30px' }}> Or you can reach us at</small>
                        <p style={{ color: '#273741', fontSize: '15px', fontWeight: '300', marginLeft: '30px', marginTop: '2px', display: 'flex', justifyItems: 'center' }}>
                            <img src="/assets/images/landing/email_icon.png" alt="" style={{ width: '20px', marginRight: '5px' }} /> connect@wellbyindia.com
                        </p>
                        <p style={{ borderBottom: '1.3px solid #79B352', width: '85%', textAlign: 'center', margin: '10px auto' }}></p>
                        <p style={{ color: '#273741', fontSize: '13px', fontWeight: '600', marginLeft: '30px' }}>Wellby Solutions Pvt. Ltd</p>
                    </div>
                </div>


            </div>
        </>
    )
}

export default InActive