import setLastScanProfileCode from './lastScanProfileCode';
import showLoading from './loading';
import updateTemplateCode from './template_code';
import updateToken from './token';
import { combineReducers } from 'redux';
const rootReducer = combineReducers({
	token: updateToken,
	showLoading: showLoading,
	templateCode: updateTemplateCode,
	lastScanProfileCode: setLastScanProfileCode
});

export default rootReducer;