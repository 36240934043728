import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setToken, updateLoaderStatus } from "../../../../core/redux/actions";
import { updateContactData, updateMedicalData, updateProfileData } from "../../../../services/api.services";
import style from './Profile.module.css';
import dateFormat from 'dateformat';
import Constants from "../../../../core/Constants";
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import { useLocation } from "react-router-dom";

function IndividualCallMaskingProfile(props) {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState({});
  const [error, setError] = useState('');
  const [profileData, setProfileData] = useState({});
  const [contactData, setContactData] = useState({});
  const [medicalData, setMedicalData] = useState({});
  const [personalFile, setPersonalFile] = useState();
  const [companyFile, setCompanyFile] = useState();

  const [cookies, setCookie] = useCookies(['user']);
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setProfile(props.profile);
  }, [props.profile]);

  useEffect(() => {
    if (location.pathname.includes('/logout/', 0)) {
      // $(`.${style['line']}`).css({
      //   position: 'static',
      //   backgroundImage: `url(${Constants.PRE_ASSETS_PATH}/assets/images/header-bg-2.png)`,
      //   backgroundSize: 'cover',
      // }).addClass(`${style.scrolled}`);
    }

    if (profile) {
      setProfileData({
        first_name: profile.first_name,
        middle_name: profile.middle_name,
        last_name: profile.last_name,
        mobile: profile.mobile,
        email: profile.email,
        official_email: profile.official_email,
        address: profile.address,
        nearby_landmark: profile.nearby_landmark,
        city: profile.city,
        state: profile.state,
        country: profile.country,
        zipcode: profile.zipcode?.toString(),
        status: profile.status === 1,
      });
      let cd = {};
      if (profile?.contacts && profile?.contacts.length > 0) {
        cd = {
          contact_name_1: profile?.contacts[0]?.contact_name,
          contact_phone_1: profile?.contacts[0]?.contact_phone,
        }
      }
      if (profile?.contacts && profile?.contacts.length > 1) {
        cd = {
          ...cd,
          contact_name_2: profile?.contacts[1]?.contact_name,
          contact_phone_2: profile?.contacts[1]?.contact_phone,
        }
      }
      setContactData({
        ...cd
      });

      setMedicalData({
        primary_physician_name: profile.primary_physician_name,
        primary_physician_mobile: profile.primary_physician_mobile,
        blood_group: profile.blood_group,
        allergies: profile.allergies,
        medical_condition: profile.medical_condition,
        medicalaim_policy_personal: profile.medicalaim_policy_personal,
        medicalaim_policy_personal_company: profile.medicalaim_policy_personal_company,
        medicalaim_policy_personal_id: profile.medicalaim_policy_personal_id,
        medicalaim_policy_personal_expiry: profile.medicalaim_policy_personal_expiry,
        medicalaim_policy_corporate: profile.medicalaim_policy_corporate,
        medicalaim_policy_corporate_company: profile.medicalaim_policy_corporate_company,
        medicalaim_policy_corporate_id: profile.medicalaim_policy_corporate_id,
        medicalaim_policy_corporate_expiry: profile.medicalaim_policy_corporate_expiry,
      });
    }
  }, [profile])

  const _updateProfileData = (key, value) => {
    setProfileData({
      ...profileData,
      [key]: value,
    })
  }

  const hideSidebar = async (e) => {
    document.getElementById('overlay').style.display = 'none';
    document.getElementsByTagName('BODY')[0].style.overflow = 'auto';
  };

  const _updateContactData = (key, value) => {
    setContactData({
      ...contactData,
      [key]: value,
    })
  }

  const _updateMedicalData = (key, value) => {
    setMedicalData({
      ...medicalData,
      [key]: value,
    })
  }

  const _updateProfile = async () => {
    dispatch(updateLoaderStatus(true));
    let result = await updateProfileData(profileData);
    if (result.data.status) {
      setError('');
      alert('Profile Updated.');
    } else {
      setError(result.data.message);
    }
    dispatch(updateLoaderStatus(false));
  }

  const _updateContacts = async () => {
    dispatch(updateLoaderStatus(true));
    const data = {
      contacts: [
        {
          contact_name: contactData.contact_name_1,
          contact_phone: contactData.contact_phone_1
        },
        {
          contact_name: contactData.contact_name_2,
          contact_phone: contactData.contact_phone_2
        }
      ]
    };
    let result = await updateContactData(data);
    if (result.data.status) {
      setError('');
      alert('Contacts Updated.');
    } else {
      setError(result.data.message);
    }
    dispatch(updateLoaderStatus(false));
  }

  const _updateMedical = async () => {
    dispatch(updateLoaderStatus(true));
    const formData = new FormData();
    Object.keys(medicalData).forEach(element => {
      formData.append(element, medicalData[element]);
    });
    // console.log(companyFile, personalFile);
    formData.append('medicalaim_policy_personal', personalFile);
    formData.append('medicalaim_policy_corporate', companyFile);
    let result = await updateMedicalData(formData);
    if (result.data.status) {
      setError('');
      alert('Medical data updated.');
    } else {
      setError(result.data.message);
    }
    dispatch(updateLoaderStatus(false));
  }

  const NoContactCard = () => (

    <div className="card-body no-data" style={{ "position": 'relative', "padding": '0' }}>
      <div style={{ display: 'flex', flex: 'column', justifyContent: 'center' }}>
        <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/no_data_icon.png`} height="100px" alt="" className="" />
      </div>

      <div style={{ 'display': 'flex', 'flex': 'column', 'justifyContent': 'center', padding: '0 20px' }}>
        <p style={{ textAlign: 'center', fontSize: '22px' }}>You have <strong style={{ 'color': 'green' }}> not
          assigned </strong>any emergency number</p>
      </div>
      <br />
      <div style={{ 'display': 'flex', 'flex': 'column', 'justifyContent': 'center' }}>
        <Link to='/edit_profile/medical_details' className={`${style.assignButton}`}>Assign Emergency Number</Link>
      </div>
    </div>
  );

  const [padding,setPadding] = useState('30px');
  useEffect(()=>{
    if($('.mainHolder').css('padding-top') == '0px'){
      setPadding('130px')
    }
  },[])

  return (
    <>
      <div className={``} style={{"height":padding}}></div>
      <div className={`card ${style.profileCard}`} >
        <div className="card-body" style={{ "position": 'relative', "padding": '0' }}>
          <div style={{ "position": 'absolute', "right": '0' }}>

            {/*  <button style={{ "background": "#FF5238", "color": '#fff', "border": 'none', "padding": '7px 15px', "borderRadius": "5px", "letterSpacing": '1px', "fontSize": '.70rem', "fontWeight": 'bold' }} to={'/logout'} onClick={() => {
               hideSidebar();
               localStorage.setItem('Token', '');
               dispatch(setToken(''));
             }}> */}
            <button style={{ "background": "#FF5238", "color": '#fff', "border": 'none', "padding": '7px 15px', "borderRadius": "5px", "letterSpacing": '1px', "fontSize": '.70rem', "fontWeight": 'bold' }}
              to={'/scanqr'} onClick={() => {
                hideSidebar();
                setCookie('Token', '', { path: '/profile' });
                localStorage.setItem('Token', '');
                dispatch(setToken(''));
                navigate(`/logout`)
              }}>
              <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/logoutbtn.png`} alt="" style={{ "height": '10px' }} className="icon" /> LOGOUT</button>
          </div>
          <label>Welcome,</label>
          <div className={`${style.profileName}`}>
            {profileData ?
              profileData.first_name + ' ' + profileData.middle_name + ' ' + profileData.last_name : null}!

          </div>
          <span className={`${style.message}`}>Looks like your contact has verified as your Emergency Connect. Always keep your Health, Medical and Emergency Contact details updated.</span>
          <div className={`${style.checkEmergencyContactVerified}`}><span style={{ "fontSize": '15px' }}>Emergency Contact Verified</span></div>
          <hr className={`${style.hr}`} />
          <div>
            <div className={`inline-block ${style.profileLastUpdated}`}><span style={{ "fontSize": '15px' }}>Profile Last Updated</span></div>
            <div className={`inline-block ${style.profileLastUpdatedDate}`}><span style={{ "fontSize": '14px', "fontWeight": 'bold' }}>
              {dateFormat(profileData.updated_at || new Date(), "dd, mmm yyyy")}
            </span></div>
          </div>
        </div>
      </div>

      <div className={`card ${style.profileCard}`}>
        <div className="card-body" style={{ "position": 'relative', "padding": '0' }}>
          <div>
            <div className={`inline-block ${style.heading}`}>Personal Details</div>
            <div className={`inline-block f-right`}>
              <Link to='/edit_profile/personal_details' className={`${style.editButton}`}>Edit</Link>
            </div>
          </div>
          <hr className={`${style.hr}`} />

          {/* Name Input Start */}
          <div className={`${style['form-group']}`} style={{}}>
            <label className={`${style['lbl']}`}>Full Name</label>
            <div className={`${style['input-group']}`}>

              <div className={`${style['form-control']}`}>
                {profileData.first_name} {profileData.middle_name} {profileData.last_name}
              </div>

            </div>
          </div>
          {/* Name Input End */}

          {/* Name Input Start */}
          <div className={`${style['form-group']}`}>
            <label className={`${style['lbl']}`}>Mobile</label>
            <div className={`${style['input-group']}`}>

              <div className={`${style['form-control']}`}>
                +91 {profileData.mobile}
              </div>

            </div>
          </div>
          {/* Name Input End */}

          {/* Official Input Start */}
          {profileData.official_email ?
            <div className={`${style['form-group']}`}>
              <label className={`${style['lbl']}`}>Offiial Email ID</label>
              <div className={`${style['input-group']}`}>

                <div className={`${style['form-control']}`}>
                  {profileData.official_email}
                </div>

              </div>
            </div> : null}
          {/* Official Input End */}

          {/* Personal Input Start */}
          {profileData.email ?
            <div className={`${style['form-group']}`}>
              <label className={`${style['lbl']}`}>Personal Email ID</label>
              <div className={`${style['input-group']}`}>

                <div className={`${style['form-control']}`}>
                  {profileData.email}
                </div>

              </div>
            </div> : null}
          {/* Personal Input End */}

          {/* Contract Company Name Input Start */}
          {profileData.contract_company_name ?
            <div className={`${style['form-group']}`}>
              <label className={`${style['lbl']}`}> Company Name</label>
              <div className={`${style['input-group']}`}>

                <div className={`${style['form-control']}`}>
                  {profileData.contract_company_name}
                </div>

              </div>
            </div>
            : null}
          {/* Contract Company Name Input End */}

          {/* Employee ID Input Start */}
          {profileData.contract_company_name ?
            <div className={`${style['form-group']}`}>
              <label className={`${style['lbl']}`}>Employee ID</label>
              <div className={`${style['input-group']}`}>

                <div className={`${style['form-control']}`}>
                  {profileData.employee_id}
                </div>

              </div>
            </div>
            : null}
          {/* Employee ID Input End */}

          {/* Address Input Start */}
          {profileData.address ?
            <div className={`${style['form-group']}`}>
              <label className={`${style['lbl']}`}>Address</label>
              <div className={`${style['input-group']}`}>

                <div className={`${style['form-control']}`}>
                  {profileData.address}
                </div>

              </div>
            </div>
            : null}
          {/* Address Input End */}

          {/* Nearby Landmark Input Start */}
          {profileData.contract_company_name ?
            <div className={`${style['form-group']}`}>
              <label className={`${style['lbl']}`}>Nearby Landmark</label>
              <div className={`${style['input-group']}`}>

                <div className={`${style['form-control']}`}>
                  {profileData.landmark}
                </div>

              </div>
            </div>
            : null}
          {/* Nearby Landmark Input End */}

          {/* Pincode Input Start */}
          {profileData.zipcode && profileData.zipcode !== '' && profileData.zipcode != '0' ?
            <div className={`${style['form-group']}`}>
              <label className={`${style['lbl']}`}>Pincode</label>
              <div className={`${style['input-group']}`}>

                <div className={`${style['form-control']}`}>
                  {profileData.zipcode === '0' ? '' : profileData.zipcode}
                </div>

              </div>
            </div>
            : null}
          {/* Pincode Input End */}

          {/* City Input Start */}
          {profileData.city ?
            <div className={`${style['form-group']}`}>
              <label className={`${style['lbl']}`}>City</label>
              <div className={`${style['input-group']}`}>

                <div className={`${style['form-control']}`}>
                  {profileData.city}
                </div>

              </div>
            </div>
            : null}
          {/* City Input End */}

          {/* State Input Start */}
          {profileData.state ?
            <div className={`${style['form-group']}`}>
              <label className={`${style['lbl']}`}>State</label>
              <div className={`${style['input-group']}`}>

                <div className={`${style['form-control']}`}>
                  {profileData.state}
                </div>

              </div>
            </div>
            : null}
          {/* State Input End */}
        </div>
      </div>

      <div className={`card ${style.profileCard}`}>
        <div className="card-body" style={{ "position": 'relative', "padding": '0' }}>
          <div>
            <div className={`inline-block ${style.heading}`}>My Emergency  Contacts</div>
            <div className={`inline-block f-right`}>
              <Link to='/edit_profile/emergency_contacts' className={`${style.editButton}`}>Edit</Link>
            </div>
          </div>
          <hr className={`${style.hr}`} />

          {
            profile?.contacts && (profile?.contacts[0]['contact_first_name'] || profile?.contacts[1]['contact_first_name']) ? '' :
              <div className="card-body" style={{ "position": 'relative', "padding": '0' }}>
                <div style={{ 'display': 'flex', 'flex': 'column', 'justifyContent': 'center' }}>
                  <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/no_data_icon.png`} height="100px" alt="" className="" />
                </div>
                <div style={{ 'display': 'flex', 'flex': 'column', 'justifyContent': 'center', padding: '0 20px' }}>
                  <p style={{ textAlign: 'center', fontSize: '22px' }}>You have <strong style={{ 'color': 'green' }}> not
                    assigned </strong>any emergency number</p>
                </div>
                <br />
                <div style={{ 'display': 'flex', 'flex': 'column', 'justifyContent': 'center' }}>
                  <Link to='/edit_profile/emergency_contacts' className={`${style.assignButton}`}>Assign Number</Link>
                </div>
              </div>

          }

          {
            profile?.contacts && profile?.contacts.length > 0 ?
              profile?.contacts.map((contact, index) => {
                if (!contact.contact_first_name && !contact.contact_last_name && !contact.contact_mobile)
                  return '';

                return <React.Fragment key={index}>
                  <div className={`${style.contactPersonLabel}`} style={{ "marginBottom": '5px' }}>Contact Person 0{index + 1}</div>

                  {/* Name Input Start */}
                  <div className={`${style['form-group']}`}>
                    <label className={`${style['lbl']}`}>Full Name</label>
                    <div className={`${style['input-group']}`}>

                      <div className={`${style['form-control']}`}>
                        {/*  {
                           contact.contact_first_name + ' ' + contact.contact_last_name */}
                        {contact.contact_first_name ?
                          contact.contact_first_name + ' ' + contact.contact_last_name : 'NA'
                        }
                      </div>

                    </div>
                  </div>
                  {/* Name Input End */}

                  {/* Mobile Input Start */}
                  <div className={`${style['form-group']}`}>
                    <label className={`${style['lbl']}`}>Mobile</label>
                    <div className={`${style['input-group']}`}>

                      <div className={`${style['form-control']}`}>
                        +91 {contact.contact_mobile || 'NA'}
                      </div>

                    </div>
                  </div>
                  {/* Mobile Input End */}

                </React.Fragment>
              })
              : null
          }
        </div>
      </div>

      <div className={`card ${style.profileCard}`}>
        <div className="card-body" style={{ "position": 'relative', "padding": '0' }}>
          <div>
            <div className={`inline-block ${style.heading}`}>My Medical Details</div>
            <div className={`inline-block f-right`}>
              <Link to='/edit_profile/medical_details' className={`${style.editButton}`}>Edit</Link>
            </div>
          </div>
          <hr className={`${style.hr}`} />
          {
            medicalData.primary_physician_name || medicalData.primary_physician_mobile || medicalData.blood_group || medicalData.blood_group || medicalData.medical_condition ?
              <>
                {/* Primary Physician Name Input Start */}
                {medicalData.primary_physician_name ?
                  <div className={`${style['form-group']}`}>
                    <label className={`${style['lbl']}`}>Primary Physician Name</label>
                    <div className={`${style['input-group']}`}>

                      <div className={`${style['form-control']}`}>
                        {medicalData.primary_physician_name}
                      </div>

                    </div>
                  </div>
                  : null}
                {/* Primary Physician Name Input End */}

                {/* Primary Physician Contact Number Input Start */}
                {medicalData.primary_physician_mobile ?
                  <div className={`${style['form-group']}`}>
                    <label className={`${style['lbl']}`}>Primary Physician Contact Number</label>
                    <div className={`${style['input-group']}`}>

                      <div className={`${style['form-control']}`}>
                        {medicalData.primary_physician_mobile}
                      </div>

                    </div>
                  </div>
                  : null}
                {/* Primary Physician Contact Number Input End */}

                {/* Blood Group Input Start */}
                {medicalData.blood_group ?
                  <div className={`${style['form-group']}`}>
                    <label className={`${style['lbl']}`}>Blood Group</label>
                    <div className={`${style['input-group']}`}>

                      <div className={`${style['form-control']}`}>
                        {medicalData.blood_group}
                      </div>

                    </div>
                  </div>
                  : null}
                {/* Blood Group Input End */}

                {/* Allergies (If Any) Input Start */}
                {medicalData.allergies ?
                  <div className={`${style['form-group']}`}>
                    <label className={`${style['lbl']}`}>Allergies (If Any)</label>
                    <div className={`${style['input-group']}`}>

                      <div className={`${style['form-control']}`}>
                        {medicalData.allergies}
                      </div>

                    </div>
                  </div>
                  : null}
                {/* Allergies (If Any) Input End */}

                {/* Medical Condition (If Any) Input Start */}
                {medicalData.medical_condition ?
                  <div className={`${style['form-group']}`}>
                    <label className={`${style['lbl']}`}>Medical Condition (If Any)</label>
                    <div className={`${style['input-group']}`}>

                      <div className={`${style['form-control']}`}>
                        {medicalData.medical_condition}
                      </div>

                    </div>
                  </div>
                  : null}
                {/* Medical Condition (If Any) Input End */}
              </>
              : <div className="card-body" style={{ "position": 'relative', "padding": '0' }}>
                <div style={{ 'display': 'flex', 'flex': 'column', 'justifyContent': 'center' }}>
                  <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/no_data_icon.png`} height="100px" alt="" className="" />
                </div>

                <div style={{ 'display': 'flex', 'flex': 'column', 'justifyContent': 'center', padding: '0 20px' }}>
                  <p style={{ textAlign: 'center', fontSize: '22px' }}>You have <strong style={{ 'color': 'green' }}> not
                    added </strong>any medical details</p>
                </div>
                <br />
                <div style={{ 'display': 'flex', 'flex': 'column', 'justifyContent': 'center' }}>
                  <Link to='/edit_profile/medical_details' className={`${style.assignButton}`}>Add Details</Link>
                </div>
              </div>
          }
        </div>
      </div>


      <div className={`card ${style.profileCard}`}>
        <div className="card-body" style={{ "position": 'relative', "padding": '0' }}>
          <div>
            <div className={`inline-block ${style.heading}`}>My Mediclaim Policies</div>
            <div className={`inline-block f-right`}>
              {/* <button className={`${style.editButton}`}>Edit</button> */}
              <Link to='/edit_profile/mediclaim_policies' className={`${style.editButton}`}>Edit</Link>

            </div>
          </div>
          <hr className={`${style.hr}`} />
          {
            !medicalData.medicalaim_policy_personal && !medicalData.medicalaim_policy_corporate ? 
            <div className="card-body" style={{ "position": 'relative', "padding": '0' }}>
                <div style={{ 'display': 'flex', 'flex': 'column', 'justifyContent': 'center' }}>
                  <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/no_data_icon.png`} height="100px" alt="" className="" />
                </div>

                <div style={{ 'display': 'flex', 'flex': 'column', 'justifyContent': 'center', padding: '0 20px' }}>
                  <p style={{ textAlign: 'center', fontSize: '22px' }}>You have <strong style={{ 'color': 'green' }}> not
                    added </strong>any mediclaim details</p>
                </div>
                <br />
                <div style={{ 'display': 'flex', 'flex': 'column', 'justifyContent': 'center' }}>
                  <Link to='/edit_profile/mediclaim_policies' className={`${style.assignButton}`}>Add Personal Policy</Link>
                </div>
              </div>: null
          }

{
            medicalData.medicalaim_policy_personal ? <>
              <div className={`${style.contactPersonLabel}`} style={{ "marginBottom": '5px' }}>Personal Mediclaim Policy</div>
              {/* Personal Mediclaim Policy Start */}
              <div style={{ height: '100px', backgroundColor: '#fff', borderRadius: '5px', border: '4px solid #eee' }}>
                {medicalData.medicalaim_policy_personal.split('.').pop() === 'pdf' ?
                  <iframe src={Constants.HOST + medicalData.medicalaim_policy_personal} width='100%' height='100px' />
                  : <><img src={Constants.HOST + medicalData.medicalaim_policy_personal} alt='' style={{ width: '100%', height: '100px', objectFit: "cover" }} /></>
                }
              </div>
              <div className={`${style['form-group']}`} style={{ marginTop: '0', paddingTop: '0' }}>

                {/* <label className={`${style['lbl']}`} style={{paddingBottom:0}}>Personal Mediclaim Policy</label> */}
                <div className={`${style['input-group']} `}>
                  <div className={` ${style['form-control']} pos-relative`}
                    style={{ backgroundColor: '#eee', border: '0', borderTopLeftRadius: '1px', borderTopRightRadius: '0' }}>
                    <div className={`${style.file} textWrap`}>
                      {Constants.getFileName(medicalData.medicalaim_policy_personal.replace(/^.*[\\/]/, ''))}
                    </div>
                    <div className={`pos-absolute ${style.downloadButton}`} onClick={() => {
                      var win = window.open(Constants.HOST + profile.medicalaim_policy_personal, '_blank');
                      win.focus();
                    }}>
                      <i className={`fa-solid fa-download`}></i>
                    </div>
                  </div>
                </div>
              </div>
              {/* Personal Mediclaim Policy End */}

              {/* Corporate Expiry Date Input Start */}
              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl']}`}>Expiry Date</label>
                <div className={`${style['input-group']}`}>
                  <div className={`${style['form-control']} pos-relative`}>
                    {medicalData.medicalaim_policy_personal_expiry ? dateFormat(medicalData.medicalaim_policy_personal_expiry, "yyyy-mm-dd") : ''}
                  </div>
                  <div className={`pos-absolute ${style.calenderButton}`}>
                    <i className={`fa-solid fa-calendar`}></i>
                  </div>
                </div>
              </div>
              {/* Corporate Expiry Date Input End */}
            </> :
              null
          }

          {
            // profileData.official_email ?<>
            medicalData.medicalaim_policy_corporate ? <>
              <div className={`${style.contactPersonLabel}`} style={{ "marginBottom": '5px' }}>Corporate Mediclaim Policy</div>
              {/* Corporate Mediclaim Policy Start */}
              <div style={{ height: '100px', backgroundColor: '#fff', borderRadius: '5px', border: '4px solid #eee' }}>
                {medicalData.medicalaim_policy_corporate.split('.').pop() === 'pdf' ?
                  <iframe src={Constants.HOST + medicalData.medicalaim_policy_corporate} width='100%' height='100px' />
                  : <><img src={Constants.HOST + medicalData.medicalaim_policy_corporate} alt='' style={{ width: '100%', height: '100px', objectFit: "cover" }} /></>
                }
              </div>
              <div className={`${style['form-group']}`} style={{ marginTop: '0', paddingTop: '0' }}>
                <div className={`${style['input-group']} `}>
                  <div className={` ${style['form-control']} pos-relative`}
                    style={{ backgroundColor: '#eee', border: '0', borderTopLeftRadius: '1px', borderTopRightRadius: '0' }}>
                    <div className={`${style.file} textWrap`}>
                      {Constants.getFileName(medicalData.medicalaim_policy_corporate.replace(/^.*[\\/]/, ''))}
                    </div>
                    <div className={`pos-absolute ${style.downloadButton}`} onClick={() => {
                      var win = window.open(Constants.HOST + profile.medicalaim_policy_corporate, '_blank');
                      win.focus();
                    }}>
                      <i className={`fa-solid fa-download`}></i>
                    </div>
                  </div>
                </div>
              </div>
              {/* Corporate Mediclaim Policy End */}

              {/* Corporate Expiry Date Input Start */}
              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl']}`}>Expiry Date</label>
                <div className={`${style['input-group']}`}>

                  <div className={`${style['form-control']}`}>
                    {medicalData.medicalaim_policy_corporate_expiry ? dateFormat(medicalData.medicalaim_policy_corporate_expiry, "yyyy-mm-dd"): ''}
                  </div>

                </div>
              </div>
              {/* Corporate Expiry Date Input End */}
            </> :
              null
            // </> : null
          }

        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/wellbylogo/footer_logo.png`} alt="" style={{ "width": '120px', "textAlign": 'center', marginTop: '50px' }} className="icon" />
        <hr className={`${style.footerHr}`} style={{ borderBottom: '.01rem dashed #787878', width: '90%', margin: '5px' }} />
        <div style={{ margin: '0 0 10px 0', fontSize: '12px' }}>
          {/* <div style={{ color: '#787878' }}>Copyright © 2024. All Rights Reserved.</div> */}
          <span style={{ color: '#787878' }}>
            Powered by:</span>
          <span>Wellby Solutions Pvt. Ltd.</span></div>
      </div>

    </>
  )
}

export default IndividualCallMaskingProfile;