import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { setToken } from "../../core/redux/actions";
import Constants from "../../core/Constants";
import $ from 'jquery';
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

function Sidebar() {
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['user']);
  const navigate = useNavigate()
  let location = useLocation();

  const hideSidebar = async (e) => {
    document.getElementById('overlay').style.display = 'none';
    document.getElementsByTagName('BODY')[0].style.overflow = 'auto';
  };
  return (
    <>
      <section className="overlay" id="overlay" style={{maxWidth:'414px',margin:'0 auto'}}>
        <div className="dvOverlay">
          <div className="btnHolder">
            <button className="btnClose"
              onClick={hideSidebar}><span>
                CLOSE </span>&#10005;</button>
          </div>
          <div className="overlayContent">
            <div className="overlayImage">
              <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/overlayImage.png`} alt="" className="image" />
            </div>
            <h4 className="heading">Manage your Account</h4>
            <p className="content">Login to your
              <span className="yellow"> WellbyQR Emergency Assistance</span> Account to update your details.
            </p>
            <div className="dvLogin">
              {token ?
                
            <button className="btnLogin" to={'/scanqr'} onClick={() => {
              hideSidebar();
              setCookie('Token', '', { path: '/profile' });
              localStorage.setItem('Token', '');
              dispatch(setToken(''));
              navigate(`/logout`)
            }}>Logout</button> :
                <button className="btnLogin" onClick={() => {
                  hideSidebar();
                  $('.dvLoginDetailsContent1').css('left', '0');
                  $('#loginOverlayTwo, .dvLoginDetails, .dvLoginDetailsContent, .dvLoginDetailsContent1').fadeIn(300);
                  $('.dvLoginOTP').fadeOut(0);
                  $('body').css('overflow', 'hidden');
                  $('#locker').text('lock_outline');
                  navigate(`/login`,{replace:false})
                  $('#mobileInput').val('');
                  
                }}>Login</button>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Sidebar;