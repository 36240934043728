import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTemplateCode } from "../../core/redux/actions";
import { useParams } from "react-router-dom";
import DefaultEditProfile from "../templates/default/edit_profile/EditProfile";
import CorporateDefaultEditProfile from "../../pages/templates/corporate_default/edit_profile/EditProfile";
import CorporateDefaultQREditProfile from "../../pages/templates/default_corporate_qr/edit_profile/EditProfile";
import CorporateCallMaskingEditProfile from '../templates/corporate_call_masking/edit_profile/EditProfile';
import IndividualCallMaskingEditProfile from '../templates/Individual_call_masking/edit_profile/EditProfile'
function EditProfile() {
  const dispatch = useDispatch();
  const { type } = useParams();
  const templateCode = useSelector((state) => state.templateCode);
  useEffect(() => {

  }, []);
  function getTemplate(templateCode) {
    switch (templateCode) {
      case 'asian_paints':
        return <></>
      case 'corporate_default':
        return <CorporateDefaultEditProfile type={type}></CorporateDefaultEditProfile>
        case 'abc_comp' :
          return <CorporateDefaultQREditProfile type={type}></CorporateDefaultQREditProfile>
        case 'corporate_call_masking' : 
          return <CorporateCallMaskingEditProfile type={type}></CorporateCallMaskingEditProfile>
          case 'individual_call_masking' : 
          return <IndividualCallMaskingEditProfile type={type}></IndividualCallMaskingEditProfile>
      default:
        return <DefaultEditProfile type={type}></DefaultEditProfile>
    }
  }

  return (
    <>
      {
        getTemplate(templateCode)
      }
    </>
  )
}

export default EditProfile;