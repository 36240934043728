import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setTemplateCode } from "../../../../core/redux/actions";
import { useNavigate, useParams } from "react-router-dom";
import { getProfile, updateContactData, updateMedicalData, updateProfileData } from "../../../../services/api.services";
import { updateLoaderStatus } from "../../../../core/redux/actions";
import style from './EditProfile.module.css';
import Constants from "../../../../core/Constants";
import dateFormat from 'dateformat';
import $ from 'jquery';

function CorporateCallMaskingEditProfile() {
  const dispatch = useDispatch();
  const { type } = useParams();
  const [profileData, setProfileData] = useState({});
  const [contactData, setContactData] = useState({});
  const [medicalData, setMedicalData] = useState({});
  const [personalFile, setPersonalFile] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const [companyFile, setCompanyFile] = useState();
  const [profile, setProfile] = useState({});
  const [error, setError] = useState('');


  const validateEmail = (email) => {
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return 'Invalid email address';
    }
    return null;  // No error
  };

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setValidationErrors({});
    dispatch(setTemplateCode('default'));
    (async () => {
      dispatch(updateLoaderStatus(true));
      try {
        let result = await getProfile();
        if (result.data.status) {
          setProfile(result.data.data);
        }
      }
      catch (err) {
        console.log(err);
      }
      dispatch(updateLoaderStatus(false));
    })();

  }, []);

  useEffect(() => {
    if (profile) {
      setProfileData({
        first_name: `${profile.first_name} ${profile.middle_name} ${profile.last_name}`,
        email: profile.email,
        official_email: profile.official_email,
        address: profile.address,
        landmark: profile.landmark,
        city: profile.city,
        state: profile.state,
        country: profile.country,
        zipcode: profile.zipcode?.toString() === '0' ? '' : profile.zipcode?.toString(),
        status: profile.status === 1,
      });
      let cd = {};
      if (profile?.contacts && profile?.contacts.length > 0) {
        cd = {
          contact_name_1: profile?.contacts[0]?.contact_first_name + ' ' + profile?.contacts[0]?.contact_last_name,
          contact_mobile_1: profile?.contacts[0]?.contact_mobile,
        }
      }
      if (profile?.contacts && profile?.contacts.length > 1) {
        cd = {
          ...cd,
          contact_name_2: profile?.contacts[1]?.contact_first_name + ' ' + profile?.contacts[1]?.contact_last_name,
          contact_mobile_2: profile?.contacts[1]?.contact_mobile,
        }
      }
      setContactData({
        ...cd
      });

      setMedicalData({
        primary_physician_name: profile.primary_physician_name,
        primary_physician_mobile: profile.primary_physician_mobile,
        blood_group: profile.blood_group,
        allergies: profile.allergies,
        medical_condition: profile.medical_condition,
        medicalaim_policy_personal: profile.medicalaim_policy_personal || '',
        medicalaim_policy_personal_company: profile.medicalaim_policy_personal_company,
        medicalaim_policy_personal_id: profile.medicalaim_policy_personal_id,
        medicalaim_policy_personal_expiry: profile.medicalaim_policy_personal_expiry,
        medicalaim_policy_corporate: profile.medicalaim_policy_corporate,
        medicalaim_policy_corporate_company: profile.medicalaim_policy_corporate_company,
        medicalaim_policy_corporate_id: profile.medicalaim_policy_corporate_id,
        medicalaim_policy_corporate_expiry: profile.medicalaim_policy_corporate_expiry,
      });
    }
  }, [profile])


  const _updateProfileData = (key, value) => {
    setProfileData({
      ...profileData,
      [key]: value,
    });

    checkProfileDataValidation(key, value);
  }

  const checkProfileDataValidation = (key, value) => {
    let error = null;
    switch (key) {
      case 'email':
        error = validateEmail(value);
        break;
      case 'zipcode':
        if (value !== '') {
          if (value.length !== 6) {
            error = 'Invlaid zipcode!';
          } else {
            error = null;
          }
        }
        break;
      // case 'state':
      //   if (!value) {
      //     error = 'Pincode is required!';
      //   } else {
      //     error = null;
      //   }
      //   break;
      default:
        break;
    }
    setValidationErrors({
      ...validationErrors,
      [key]: error,
    });
    console.log(error);
    return error;
  }

  const _updateContactData = (key, value) => {

    setContactData({
      ...contactData,
      [key]: value,
    });
    checkupdatecontactDataValidation(key, value);
  }
  const checkupdatecontactDataValidation = (key, value) => {
    let error = null;
    switch (key) {
      case 'contact_name_1':
      case 'contact_name_2':
        // if (!value) {
        //   error = 'Contact name is required!';
        // } else {
        error = null;
        // }
        break;
      case 'contact_mobile_1':
      case 'contact_mobile_2':
        // if (!value) {
        //   error = 'Contact mobile is required!';
        // } else 
        if (value && (isNaN(value) || value.length !== 10)) {
          error = 'Please enter a valid mobile number!';
        } else {
          error = null;
        }
        break;
      default:
        break;
    }
    setValidationErrors({
      ...validationErrors,
      [key]: error,
    });
    return error;
  }
  const _updateMedicalData = (key, value) => {
    setMedicalData({
      ...medicalData,
      [key]: value,
    });
    checkMedicalDataValidation(key, value);
  }

  const checkMedicalDataValidation = (key, value) => {
    let error = null;
    switch (key) {
      case 'primary_physician_name':
        break;
      case 'primary_physician_mobile':
        if (value) {
          if (isNaN(value) || value.length !== 10) {
            error = 'Please enter a valid mobile number!';
          }
        }
        break;
      case 'blood_group':
        break;
      case 'allergies':
        break;
      case 'medical_condition':
        break;
      default:
        break;
    }
    setValidationErrors({
      ...validationErrors,
      [key]: error,
    });
    return error;
  }

  // useEffect(()=>{
  //   console.log("Medical Data:",medicalData);
  // },[medicalData])

  const _updateProfile = async () => {
    let isError = false;
    Object.keys(profileData).forEach((key) => {
      let r = checkProfileDataValidation(key, profileData[key])
      if (r != null) {
        isError = true;
      }
    });
    if (isError) {
      return;
    }
    dispatch(updateLoaderStatus(true));
    let data = Object.assign({}, profileData);
    delete data.first_name;
    data.zipcode = data.zipcode === '' ? '0' : data.zipcode;
    let result = await updateProfileData(data);
    dispatch(updateLoaderStatus(false));
    if (result.data.status) {
      setError('');
      alert('Profile Updated.');
      navigate(-1);
    } else {
      setError(result.data.message);
    }
  }

  const _updateContacts = async () => {
    let isError = false;
    Object.keys(contactData).forEach((key) => {
      let r = checkupdatecontactDataValidation(key, contactData[key])
      if (r != null) {
        isError = true;
      }
    });
    if (isError) {
      return;
    }
    dispatch(updateLoaderStatus(true));

    const data = {
      contacts: [
        {
          contact_first_name: contactData.contact_name_1.trim().split(' ')[0],
          contact_last_name: contactData.contact_name_1.trim().split(' ')[1] || '',
          contact_mobile: contactData.contact_mobile_1
        },
        {
          contact_first_name: contactData.contact_name_2.trim().split(' ')[0],
          contact_last_name: contactData.contact_name_2.trim().split(' ')[1] || '',
          contact_mobile: contactData.contact_mobile_2
        }
      ]
    };
    let result = await updateContactData(data);
    if (result.data.status) {
      setError('');
      alert('Contacts Updated.');
      navigate(-1);
    } else {
      setError(result.data.message);
    }
    dispatch(updateLoaderStatus(false));
  }

  const _updateMedical = async () => {
    let isError = false;
    Object.keys(medicalData).forEach((key) => {
      let r = checkMedicalDataValidation(key, medicalData[key])
      if (r != null) {
        isError = true;
      }
    });
    if (isError) {
      return;
    }
    dispatch(updateLoaderStatus(true));
    const formData = new FormData();
    Object.keys(medicalData).forEach(element => {
      formData.append(element, medicalData[element]);
    });
    // formData.append('medicalaim_policy_personal', personalFile);
    // formData.append('medicalaim_policy_corporate', companyFile);
    let result = await updateMedicalData(formData);
    if (result.data.status) {
      setError('');
      alert('Medical data updated.');
      navigate(-1);
    } else {
      setError(result.data.message);
    }
    dispatch(updateLoaderStatus(false));
  }
  return (
    <>
      <div className={``} style={{ "height": "30px" }}></div>
      <div>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <i className={`fa-solid fa-home`} style={{ color: '#fff', backgroundColor: '#ffc505', margin: '20px 10px 2px 20px', padding: '5px', borderRadius: '50px', fontSize: '12px' }}></i>
          <span style={{ fontSize: '1rem', fontWeight: '500' }} onClick={() => {
            navigate(-1)
          }}>Return to Home</span>
        </div>
        <p style={{ color: '#787878', fontSize: '0.78rem', marginLeft: '20px' }}>My Profile &gt;  Edit Details</p>
      </div>

      <div className={`card ${style.profileCard}`} style={{ marginTop: '40px' }}>

        <div className="card-body" style={{ "position": 'relative', "padding": '0' }}>
          {
            type && type === 'personal_details' ? <>

              <div className={`inline-block ${style.heading}`}>Personal Details</div>
              <hr />


              {/* Name Input */}
              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Full Name</label>
                <div className={`${style['input-group']}`} >
                  <label className={`${style['form-control']}`} >
                    <input type="text" className={`${style['form-control']}`} value={profileData.first_name} disabled
                      placeholder="Enter your name" onChange={(e) => _updateProfileData('first_name', e.target.value)} />
                  </label>
                </div>
              </div>

              {/* Mobile Input */}

              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Mobile</label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`}>
                    <input type="text" className={`${style['form-control']}`} value={profile.mobile} disabled
                      placeholder="Enter your mobile" onChange={(e) => _updateProfileData('mobile', e.target.value)} />
                  </label>
                </div>
              </div>



              {/* Official Email Input */}
              {/* <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Official Email ID</label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`}>
                    <input type="text" className={`${style['form-control']}`} value={profile.official_email} disabled
                      placeholder="ENTER YOUR EMAIL" onChange={(e) => _updateProfileData('email', e.target.value)} />
                  </label>
                </div>
              </div> */}

              {/* Email Input */}
              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Personal Email ID</label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`} >
                    <input type="text" id="profileEmail"
                      className={`${style['form-control']}`}
                      value={profileData.email}
                      style={{ backgroundColor: '#fff', color: '#3c3c3c', border: '1px solid #787878' }}
                      placeholder="Enter your email" onChange={(e) => _updateProfileData('email', e.target.value)} />
                    {/* {emailError && <p style={{ color: 'red' }}>{emailError}</p>} */}
                  </label>
                </div>
              </div>
              {validationErrors['email'] && <p style={{ marginTop: '-25px', color: 'red' }}>{validationErrors['email']}</p>}


              {/* Company Input */}
              {profileData.contract_company_name ?
                <div className={`${style['form-group']}`}>
                  <label className={`${style['lbl1']}`}>Company</label>
                  <div className={`${style['input-group']}`}>
                    <label className={`${style['form-control']}`}>
                      <input type="text" className={`${style['form-control']}`} value={profileData.contract_company_name} disabled
                        placeholder="Enter company name" onChange={(e) => _updateProfileData('email', e.target.value)} />
                    </label>
                  </div>
                </div> : null}

              {/* Employee ID Input */}
              {profileData.employee_id ?
                <div className={`${style['form-group']}`}>
                  <label className={`${style['lbl1']}`}>Employee ID</label>
                  <div className={`${style['input-group']}`}>
                    <label className={`${style['form-control']}`}>
                      <input type="text" className={`${style['form-control']}`} value={profileData.employee_id} disabled
                        placeholder="Enter employee id" onChange={(e) => _updateProfileData('employee_id', e.target.value)} />
                    </label>
                  </div>
                </div>
                : null}

              {/* city */}
              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Address</label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`}>
                    <input type="text" className={`${style['form-control']}`} value={profileData.address}
                      placeholder="Enter address" onChange={(e) => _updateProfileData('address', e.target.value)} />
                  </label>
                </div>
              </div>

              {/* landmark */}
              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Landmark</label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`}>
                    <input type="text" className={`${style['form-control']}`} value={profileData.landmark}
                      style={{ backgroundColor: '#fff', color: '#3c3c3c', border: '1px solid #787878' }}
                      placeholder="Enter landmark" onChange={(e) => _updateProfileData('landmark', e.target.value)} />
                  </label>
                </div>
              </div>


              {/* city */}
              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>City</label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`}>
                    <input type="text" className={`${style['form-control']}`} value={profileData.city}
                      style={{ backgroundColor: '#fff', color: '#3c3c3c', border: '1px solid #787878' }}
                      placeholder="Enter city" onChange={(e) => _updateProfileData('city', e.target.value)} />
                  </label>
                </div>
              </div>

              {/* State */}
              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>State</label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`}>
                    <input type="text" className={`${style['form-control']}`} value={profileData.state}
                      style={{ backgroundColor: '#fff', color: '#3c3c3c', border: '1px solid #787878' }}
                      placeholder="Enter state" onChange={(e) => _updateProfileData('state', e.target.value)} />
                  </label>
                </div>
              </div>

              {/* zipcode */}
              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Pincode</label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`}>
                    <input type="text" className={`${style['form-control']}`}
                      style={{ backgroundColor: '#fff', color: '#3c3c3c', border: '1px solid #787878' }}
                      value={profileData.zipcode} onKeyPress={(e) => { Constants.checkPhoneKey(e) }}
                      placeholder="Enter pincode" onChange={(e) => _updateProfileData('zipcode', e.target.value)} maxLength={6} minLength={6} />
                  </label>
                </div>
              </div>
              {validationErrors['zipcode'] && <p style={{ marginTop: '-25px', color: 'red' }}>{validationErrors['zipcode']}</p>}

            </> : null
          }
          {
            type && type === 'mediclaim_policies' ? <>
              <div className={`inline-block ${style.heading}`}>Mediclaim Policies</div>
              <hr />

              {/* ------------------------------------------------------- */}
              <div className={`${style.contactPersonLabel}`} style={{ "marginBottom": '15px' }}>Personal Mediclaim Policy
                {/* <i className={`fa fa-solid fa-trash`} style={{padding: '5px 10px', float:'right', color:'white', background:'#ff9e00', borderRadius: 5, cursor:'pointer'}}
                  onClick={()=>{
                    setPersonalFile(null);
                    setMedicalData({
                      ...medicalData,
                      medicalaim_policy_personal: '',
                      medicalaim_policy_personal_expiry: ''
                    });
                  }}
                /> */}
              </div>
              {/* Primary physician Name Input */}

              <div style={{ height: '100px', backgroundColor: '#fff', borderRadius: '5px', border: '4px solid #eee' }}>
                {
                  medicalData.medicalaim_policy_personal && personalFile == null ? <>
                    {
                      (medicalData.medicalaim_policy_personal && medicalData.medicalaim_policy_personal?.split  && medicalData.medicalaim_policy_personal?.split('.').pop() === 'pdf') ?
                        <iframe src={Constants.HOST + medicalData.medicalaim_policy_personal} width='100%' height='100px' />
                        : <><img src={Constants.HOST + medicalData.medicalaim_policy_personal} alt='' style={{ width: '100%', height: '100px', objectFit: "cover" }} /></>
                    }
                  </> : <>
                    {
                      personalFile ? 
                      <>
                      {
                      (medicalData.medicalaim_policy_personal?.name?.split('.').pop() === 'pdf') ?
                        <iframe  src={URL.createObjectURL(medicalData.medicalaim_policy_personal)}  width='100%' height='100px' />
                        : <><img src={personalFile} alt='' style={{ width: '100%', height: '100px', objectFit: "cover" }} /></>
                    }
                      </>
                      :null
                    }
                  </>
                }
              </div>
              <div style={{ backgroundColor: '#eee', height: '35px' }}>

                <div className={`inline-block`}>
                  {/* <i className="fa fa-download" style={{ margin: '8px' }}></i> */}
                  &nbsp;<span style={{ "fontSize": '12px', color: 'grey' }}>
                    {
                      medicalData.medicalaim_policy_personal?.name ?
                        Constants.getFileName(medicalData.medicalaim_policy_personal.name?.replace(/^.*[\\/]/, '')) :
                        Constants.getFileName(medicalData.medicalaim_policy_personal?.replace(/^.*[\\/]/, ''))
                    }
                  </span></div>
                <div className={`inline-block ${style.profileLastUpdatedDate}`}>
                  <button style={{ border: 'none', backgroundColor: '#ff9e00', color: '#fff', fontSize: '15px', fontWeigh: 'bold', padding: '5px', borderRadius: '5px', margin: '3px 10px 0 0' }}
                    onClick={() => { $('#personalFileInput').click() }}>
                    <i className="fa-solid fa-file-arrow-up"></i>
                    <span style={{ fontSize: '14px' }}>&nbsp; Update</span></button>

                  <i className={`fa fa-solid fa-trash`} style={{ cursor: 'pointer', fontSize: '18px', color: '#3c3c3c', margin: '8px' }}
                    onClick={() => {
                      setPersonalFile(null);
                      setMedicalData({
                        ...medicalData,
                        medicalaim_policy_personal: '',
                        medicalaim_policy_personal_expiry: ''
                      });
                    }}
                  />

                </div>
                <input type="file" id='personalFileInput' className={`${style['form-control']} ${style['activeInput']}`} style={{ display: 'none' }}
                  placeholder="" onChange={(event) => {
                    if (event.target.files) {
                      // this.logo = [];
                      console.log(event.target.files[0]);
                      _updateMedicalData('medicalaim_policy_personal', event.target.files[0]);
                      // if (this.other.preview == true) {
                      if (event.target.files) {
                        var reader = new FileReader();
                        reader.readAsDataURL(event.target.files[0]);
                        reader.onload = (e) => {
                          setPersonalFile(e.target.result);
                        }
                      }
                    }
                  }} />
              </div>
              {/* <div className={`${style['form-group']}`}>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`}>
                    <input type="text" className={`${style['form-control']} ${style['activeInput']}`} value={medicalData.primary_physician_name}
                      placeholder="" onChange={(e) => _updateMedicalData('primary_physician_name', e.target.value)} />
                  </label>
                </div>
              </div> */}

              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Expiry Date </label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`} >
                    <input type="date" className={`${style['form-control']} ${style['activeInput']}`} value={medicalData.medicalaim_policy_personal_expiry ? dateFormat(medicalData.medicalaim_policy_personal_expiry, "yyyy-mm-dd") : ''}
                      placeholder="" onChange={(e) => _updateMedicalData('medicalaim_policy_personal_expiry', e.target.value)} />
                  </label>
                </div>
              </div>


              {/* ---------------------------------------------- */}


              <div className={`${style.contactPersonLabel}`} style={{ "marginBottom": '15px' }}>Corporate Mediclaim Policy
               
              </div>
              {/* Primary physician Name Input */}

              <div style={{ height: '100px', backgroundColor: '#fff', borderRadius: '5px', border: '4px solid #eee' }}>
                {

                    medicalData.medicalaim_policy_corporate && companyFile == null ? <>
                    {
                      (medicalData.medicalaim_policy_corporate && medicalData.medicalaim_policy_corporate?.split  && medicalData.medicalaim_policy_corporate?.split('.').pop() === 'pdf') ?
                        <iframe src={Constants.HOST + medicalData.medicalaim_policy_corporate} width='100%' height='100px' />
                        : <><img src={Constants.HOST + medicalData.medicalaim_policy_corporate} alt='' style={{ width: '100%', height: '100px', objectFit: "cover" }} /></>
                    }
                    </> : <>
                    {
                      companyFile ? 
                      <>
                      {
                      (medicalData.medicalaim_policy_corporate?.name?.split('.').pop() === 'pdf') ?
                        <iframe  src={URL.createObjectURL(medicalData.medicalaim_policy_corporate)}  width='100%' height='100px' />
                        : <><img src={companyFile} alt='' style={{ width: '100%', height: '100px', objectFit: "cover" }} /></>
                    }
                      </>
                      :null
                    }
                    </>
                }
              </div>
              <div style={{ backgroundColor: '#eee', height: '35px' }}>

                <div className={`inline-block`}>
                  {/* <i className="fa fa-download" style={{ margin: '8px' }}></i> */}
                  &nbsp;<span style={{ "fontSize": '12px', color: 'grey' }}>
                    {medicalData.medicalaim_policy_corporate?.name ? Constants.getFileName(medicalData.medicalaim_policy_corporate.name?.replace(/^.*[\\/]/, '')) : Constants.getFileName(medicalData.medicalaim_policy_corporate?.replace(/^.*[\\/]/, ''))}
                  </span></div>
                <div className={`inline-block ${style.profileLastUpdatedDate}`}>
                  <button style={{ border: 'none', backgroundColor: '#ff9e00', color: '#fff', fontSize: '15px', fontWeigh: 'bold', padding: '5px', borderRadius: '5px', margin: '3px 10px 0 0' }}
                    onClick={() => { $('#companyFileInput').click() }}>
                    <i className="fa-solid fa-file-arrow-up"></i>
                    <span style={{ fontSize: '14px' }}>&nbsp; Update</span></button>
                  <i className={`fa fa-solid fa-trash`} style={{ cursor: 'pointer', fontSize: '18px', color: '#3c3c3c', margin: '8px' }}
                    onClick={() => {
                      setCompanyFile(null);
                      setMedicalData({
                        ...medicalData,
                        medicalaim_policy_corporate: '',
                        medicalaim_policy_corporate_expiry: ''
                      });
                    }}
                  />
                </div>
                <input type="file" id='companyFileInput' className={`${style['form-control']} ${style['activeInput']}`} style={{ display: 'none' }}
                  placeholder="" onChange={(event) => {
                    if (event.target.files) {
                      // this.logo = [];
                      _updateMedicalData('medicalaim_policy_corporate', event.target.files[0]);
                      // if (this.other.preview == true) {
                      if (event.target.files) {
                        var reader = new FileReader();
                        reader.readAsDataURL(event.target.files[0]);
                        reader.onload = (e) => {
                          setCompanyFile(e.target.result);
                        }
                      }
                    }
                  }} />
              </div>
              {/* <div className={`${style['form-group']}`}>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`}>
                    <input type="text" className={`${style['form-control']} ${style['activeInput']}`} value={medicalData.primary_physician_name}
                      placeholder="" onChange={(e) => _updateMedicalData('primary_physician_name', e.target.value)} />
                  </label>
                </div>
              </div> */}

              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Expiry Date </label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`} >
                    <input type="date" className={`${style['form-control']} ${style['activeInput']}`} value={medicalData.medicalaim_policy_corporate_expiry ? dateFormat(medicalData.medicalaim_policy_corporate_expiry, "yyyy-mm-dd") : ''}
                      placeholder="" onChange={(e) => _updateMedicalData('medicalaim_policy_corporate_expiry', e.target.value)} />
                  </label>
                </div>
              </div>



            </>

              : null
          }
          {
            type && type === 'medical_details' ? <>
              <div className={`inline-block ${style.heading}`}>Medical Details</div>
              <hr />

              {/* Primary physician Name Input */}
              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Primary Physician Name</label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`} >
                    <input type="text" className={`${style['form-control']} ${style['activeInput']}`} value={medicalData.primary_physician_name}
                      placeholder="Enter physician name" onChange={(e) => _updateMedicalData('primary_physician_name', e.target.value)} />
                  </label>
                </div>
              </div>
              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Primary Physician Contact Number</label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`} >
                    <input type="text" className={`${style['form-control']} ${style['activeInput']}`} value={medicalData.primary_physician_mobile}
                      minLength="10" maxLength="10" onKeyPress={(e) => { Constants.checkPhoneKey(e) }}
                      placeholder="Enter physician contact number" onChange={(e) => _updateMedicalData('primary_physician_mobile', e.target.value)} />
                  </label>
                </div>
              </div>
              {validationErrors['primary_physician_mobile'] && <p style={{ marginTop: '-25px', color: 'red' }}>{validationErrors['primary_physician_mobile']}</p>}
              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Blood Group</label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`} >
                    <input type="text" className={`${style['form-control']} ${style['activeInput']}`} value={medicalData.blood_group}
                      placeholder="Enter your blood group" onChange={(e) => _updateMedicalData('blood_group', e.target.value)} />
                  </label>
                </div>
              </div>
              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Allergies (If Any)</label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`} >
                    <input type="text" className={`${style['form-control']} ${style['activeInput']}`} value={medicalData.allergies}
                      placeholder="Allergies (If Any)" onChange={(e) => _updateMedicalData('allergies', e.target.value)} />
                  </label>
                </div>
              </div>
              <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Medical Condition (If Any)</label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`} >
                    <input type="text" className={`${style['form-control']} ${style['activeInput']}`} value={medicalData.medical_condition}
                      placeholder="Medical Condition (If Any)" onChange={(e) => _updateMedicalData('medical_condition', e.target.value)} />
                  </label>
                </div>
              </div>
              {/* <div className={`${style['form-group']}`}>
                <label className={`${style['lbl1']}`}>Mediclaim Policy</label>
                <div className={`${style['input-group']}`}>
                  <label className={`${style['form-control']}`} >
                    <input type="text" className={`${style['form-control']} ${style['activeInput']}`} value={medicalData.medical_condition}
                      placeholder="Medical Condition (If Any)" onChange={(e) => _updateMedicalData('medical_condition', e.target.value)} />
                  </label>
                </div>
              </div> */}
            </>

              : null
          }
          {
            type && type === 'emergency_contacts' ? <>
              <div className={`inline-block ${style.heading}`}>My Emergency Contacts</div>
              <hr />
              {
                profile?.contacts && profile?.contacts.length > 0 ?
                  profile?.contacts.map((contact, index) => {

                    const contactName = contactData['contact_name_' + (index + 1)];
                    // const contactMobile = contactData['contact_mobile_' + (index + 1)];

                    return <React.Fragment key={index}>
                      <div className={`${style.contactPersonLabel}`} style={{ "marginBottom": '5px' }}>Contact Person 0{index + 1}</div>

                      {/* Name Input Start */}
                      <div className={`${style['form-group']}`}>
                        <label className={`${style['lbl1']}`}>Contact Name</label>
                        <div className={`${style['input-group']}`}>
                          <label className={`${style['form-control']}`}>
                            <input type="text"
                              placeholder="Enter contact name"
                              className={`${style['form-control']}
                              ${style['activeInput']}`}
                              value={contactName ? contactName.trim() : ''}
                              onChange={(e) => _updateContactData('contact_name_' + (index + 1), e.target.value)} />
                          </label>
                        </div>
                      </div>
                      {validationErrors['contact_name_' + (index + 1)] && <p style={{ marginTop: '-25px', color: 'red' }}>{validationErrors['contact_name_' + (index + 1)]}</p>}
                      {/* Name Input End */}

                      {/* Mobile Input Start */}

                      <div className={`${style['form-group']}`}>
                        <label className={`${style['lbl1']}`}>Contact Number</label>
                        <div className={`${style['input-group']}`}>
                          <label className={`${style['form-control']}`}>
                            <input type="text" className={`${style['form-control']}
                             ${style['activeInput']}`}
                              placeholder="Enter contact number"
                              value={contactData['contact_mobile_' + (index + 1)]}
                              minLength="10"
                              maxLength="10"
                              onKeyPress={(e) => { Constants.checkPhoneKey(e) }}
                              onChange={(e) => _updateContactData('contact_mobile_' + (index + 1), e.target.value)} />
                          </label>
                        </div>
                      </div>
                      {validationErrors['contact_mobile_' + (index + 1)] && <p style={{ marginTop: '-25px', color: 'red' }}>{validationErrors['contact_mobile_' + (index + 1)]}</p>}

                      {/* <div className={`${style['form-group']}`}>
                        <label className={`${style['lbl1']}`}>Contact Number</label>
                        <div className={`${style['input-group']}`}>
                          <label className={`${style['form-control']}`}>
                            <input type="text" className={`${style['form-control']} 
                            ${style['mobileInput']} ${style['activeInput']}`} value={contactData['contact_mobile_' + (index + 1)]}
                              placeholder="ENTER MOBILE" onChange={(e) => _updateContactData('contact_mobile_' + index, e.target.value)} />
                          </label>
                        </div>
                      </div> */}

                      {/* Mobile Input End */}
                    </React.Fragment>
                  })
                  : ''
              }
            </> : null
          }
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px 0' }}>
          <div style={{}}>
            <button className={`${style.cancelBtn}`} onClick={(e) => {
              navigate(-1);
            }} >
              Cancel</button>
          </div>
          <div>
            <button className={`${style.saveUpdateBtn}`} onClick={(e) => {
              switch (type) {
                case 'personal_details':
                  _updateProfile();
                  break;
                case 'emergency_contacts':
                  _updateContacts();
                  break;
                case 'medical_details':
                  _updateMedical();
                  break;
                case 'mediclaim_policies':
                  _updateMedical();
                  break;
                default:
                  break;
              }
            }}>
              Save and Update</button>
          </div>
        </div>

      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <img src={`${Constants.PRE_ASSETS_PATH}/assets/images/wellbylogo/footer_logo.png`} alt="" style={{ "width": '120px', "textAlign": 'center', marginTop: '50px' }} className="icon" />
        <hr className={`${style.footerHr}`} style={{ borderTop: '0.01rem dashed #787878', width: '90%', margin: '5px' }} />
        <div style={{ margin: '0 0 10px 0', fontSize: '12px' }}><span style={{ color: '#787878' }}> Powered by:</span> <span>Wellby Solutions Pvt. Ltd.</span></div>
      </div>
    </>
  )
}

export default CorporateCallMaskingEditProfile;